import { FC } from 'react';
import { Step } from '../../../../shared/step-navigation';
import { useSsoStepRoutes } from '../../hooks/use-sso-step-routes';
import { MetadataUrlFormFields } from '../../interfaces/metadata-form-fields';
import { SsoStepNavigation } from '../../sso-step-navigation';
import { generateSsoSteps } from '../../utils/generate-sso-steps';
import { createASelfSignedCertificate } from './1-create-a-self-signed-certificate';
import { enableSalesforceAsAnIdp } from './2-enable-salesforce-as-an-idp';
import { uploadIdentityProviderMetadata } from './3-upload-identity-provider-metadata';
import { createAConnectedApp } from './4-create-a-connected-app';
import { mapUserAttributes } from './5-map-user-attributes';
import { addUserProfiles } from './6-add-user-profiles';

interface SalesforceSamlStepsProps {}

const steps: Step<MetadataUrlFormFields>[] = generateSsoSteps([
  createASelfSignedCertificate,
  enableSalesforceAsAnIdp,
  uploadIdentityProviderMetadata,
  createAConnectedApp,
  mapUserAttributes,
  addUserProfiles,
]);

export const SalesforceSamlSteps: FC<
  Readonly<SalesforceSamlStepsProps>
> = () => {
  const stepRoutes = useSsoStepRoutes(steps);

  return <SsoStepNavigation steps={steps}>{stepRoutes}</SsoStepNavigation>;
};
