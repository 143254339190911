import { Pill, Text } from '@workos-inc/component-library';
import { FC } from 'react';
import { Img } from '../../../../components/image';
import { StepData } from '../../../../shared/step-navigation/interfaces';
import { StepContent } from '../../../../shared/step-navigation/step-content';
import { useDsyncStore } from '../../dsync-store-provider';

const Component: FC = () => {
  const { directoryCustomAttributes } = useDsyncStore();

  return (
    <StepContent confirmLabel="I've created and populated the custom reports.">
      <Text>
        You will need to create two Custom Reports. The first Custom Report will
        be used for syncing User information. The second report will be used for
        syncing Group information.
      </Text>

      <Text>
        When creating the report, make sure to select the Advanced report type
        and to have the Enable as Web Service box checked.
      </Text>

      <Img
        className="my-8"
        height={1172}
        src="/images/21b7fe82-5494-48ea-9b05-de15c1bcd609.png"
        width={2248}
      />

      <Text>
        You need to add information for certain fields to the report. You can do
        this by directly adding columns to the report for the attributes in
        Workday that contain the following information:
      </Text>

      <ul className="my-4 list-inside list-disc space-y-3 text-gray-lightmode-300">
        <li>Unique ID</li>
        <li>Username</li>
        <li>Email</li>
        <li>First Name</li>
        <li>Last Name</li>
        <li>
          Job Title <Pill size="small">Optional</Pill>
        </li>
        <li>Group Name</li>

        {directoryCustomAttributes?.map((customAttribute) => (
          <li key={customAttribute.id}>{customAttribute.name}</li>
        ))}
      </ul>

      <Img
        className="my-8"
        height={496}
        src="/images/f01eb42a-139f-4502-9c13-9b22384b8d8a.png"
        width={1920}
      />

      <Text>
        Along the same lines as the User Report, you need to add a column to the
        Group Report for an attribute that contains the following information:
      </Text>

      <ul className="my-4 list-inside list-disc space-y-3 text-gray-lightmode-300">
        <li>Group Name</li>
      </ul>
    </StepContent>
  );
};

export const createAndPopulateCustomReports: StepData = {
  title: 'Create and populate custom reports',
  component: Component,
};
