import { Text } from '@workos-inc/component-library';
import { FC } from 'react';
import { ExternalLink } from '../../../../components/link';
import { StepData } from '../../../../shared/step-navigation/interfaces';
import { metadataUrlFormKeys } from '../../constants/metadata-form-keys';
import { MetadataUrlFormFields } from '../../interfaces/metadata-form-fields';
import { MetadataUrlStep } from '../../metadata-step';

const Component: FC = () => (
  <MetadataUrlStep
    idpEntityIdPlaceholder="https://myoracleserver.com/abc123..."
    idpSsoUrlPlaceholder="https://myoracleserver.com/..."
    metadataUrlPlaceholder="https://"
  >
    <Text>
      Obtain the IdP Metadata URL following the{' '}
      <ExternalLink
        newTab
        appearance="highlight"
        href="https://docs.oracle.com/en/cloud/paas/identity-cloud/uaids/access-saml-metadata.html"
      >
        instructions from Oracle
      </ExternalLink>
      . Then paste the Metadata URL into the corresponding field below.
    </Text>

    <Text>
      Alternatively, you can manually configure the connection by providing the
      IdP URI (Entity ID), IdP SSO URL and X.509 Certificate.
    </Text>

    <Text as="h2" size="large" weight="medium">
      URL Metadata Configuration
    </Text>
  </MetadataUrlStep>
);

export const basicSamlConfiguration: StepData<MetadataUrlFormFields> = {
  title: 'Basic SAML Configuration',
  component: Component,
  formKeys: metadataUrlFormKeys,
};
