import { Text } from '@workos-inc/component-library';
import { FC } from 'react';
import { Img } from '../../../../components/image';
import { StepData } from '../../../../shared/step-navigation/interfaces';
import { StepContent } from '../../../../shared/step-navigation/step-content';

const Component: FC = () => (
  <StepContent confirmLabel="I’ve finished configuring the SAML response settings.">
    <Text>
      Scroll down on this page in Duo to the SAML Response section. Ensure that
      the NameID format has the id that you’d like to use for the unique
      identifier selected and matches the NameID attribute that you’d like to
      use as the value. If you’re using email as the unique id, the options
      would look like the below.
    </Text>

    <Img
      className="my-8"
      height={1426}
      src="/images/1f7a60a3-2c15-4d99-9ec2-b0df0895fabc.png"
      width={2226}
    />

    <Text>
      Ensure the Signature algorithm is SHA256 and that the Signing options have
      both Sign response and Sign assertion selected.
    </Text>

    <Img
      className="my-8"
      height={1426}
      src="/images/0349fcdd-a458-4691-8626-78243081f47e.png"
      width={2226}
    />
  </StepContent>
);

export const configureSamlResponseSettingsInDuo: StepData = {
  title: 'Configure SAML Response Settings in Duo',
  component: Component,
};
