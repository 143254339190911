import { INITIATE_SSO_CONNECTION_PARAM } from '../sso-test-connection-redirect-manager';

type TestConnectionOptions = { shouldRedirectToSuccessUrl?: boolean };

export const testConnection = (
  connectionId: string,
  { shouldRedirectToSuccessUrl }: TestConnectionOptions = {},
) => {
  if (shouldRedirectToSuccessUrl) {
    window.open(`/?${INITIATE_SSO_CONNECTION_PARAM}=${connectionId}`, '_blank');
  } else {
    window.open(
      `${process.env.NEXT_PUBLIC_API_URL}/sso/authorize/portal?connection=${connectionId}`,
      '_blank',
    );
  }
};
