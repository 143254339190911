import { FC } from 'react';
import { Step } from '../../../../shared/step-navigation/interfaces';
import { DsyncStepNavigation } from '../../dsync-step-navigation';
import { useDsyncStepRoutes } from '../../hooks/use-dsync-step-routes';
import { useEnabledDsyncSteps } from '../../hooks/use-enabled-dsync-steps';
import { generateDsyncSteps } from '../../utils/generate-dsync-steps';
import { installTheScimConnectorInPingFederate } from './1-install-the-scim-connector-in-ping-federate';
import { createAScimConnectorApplicationInPingFederate } from './2-create-a-scim-connector-application-in-ping-federate';
import { configureOutboundProvisioningForThePingFederateApplication } from './3-configure-outbound-provisioning-for-the-ping-federate-application';
import { configureChannelInPingFederate } from './4-configure-channel-in-ping-federate';
import { configureAttributeMappingForYourPingFederateApplication } from './5-configure-attribute-mapping-for-your-ping-federate-application';
import { activateTheSpConnectionInPingFederate } from './6-activate-the-sp-connection-in-ping-federate';
import { setUpAttributeMapping } from './7-set-up-attribute-mapping';

interface PingFederateStepsProps {}

const steps: Step[] = generateDsyncSteps([
  installTheScimConnectorInPingFederate,
  createAScimConnectorApplicationInPingFederate,
  configureOutboundProvisioningForThePingFederateApplication,
  configureChannelInPingFederate,
  configureAttributeMappingForYourPingFederateApplication,
  activateTheSpConnectionInPingFederate,
  setUpAttributeMapping,
]);

export const PingFederateSteps: FC<Readonly<PingFederateStepsProps>> = () => {
  const enabledSteps = useEnabledDsyncSteps(steps);
  const stepRoutes = useDsyncStepRoutes(enabledSteps);

  return (
    <DsyncStepNavigation steps={enabledSteps}>{stepRoutes}</DsyncStepNavigation>
  );
};
