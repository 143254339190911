import { FC } from 'react';
import { Step } from '../../../../shared/step-navigation';
import { useSsoStepRoutes } from '../../hooks/use-sso-step-routes';
import { MetadataXmlFormFields } from '../../interfaces/metadata-form-fields';
import { SsoStepNavigation } from '../../sso-step-navigation';
import { generateSsoSteps } from '../../utils/generate-sso-steps';
import { createSamlApplication } from './1-create-saml-application';
import { submitAcsUrlAndEntityId } from './2-submit-acs-url-and-entity-id';
import { configureAttributeStatements } from './3-configure-attribute-statements';
import { uploadIdentityProviderMetadata } from './4-upload-identity-provider-metadata';

interface JumpCloudSamlStepsProps {}

const steps: Step<MetadataXmlFormFields>[] = generateSsoSteps([
  createSamlApplication,
  submitAcsUrlAndEntityId,
  configureAttributeStatements,
  uploadIdentityProviderMetadata,
]);

export const JumpCloudSamlSteps: FC<Readonly<JumpCloudSamlStepsProps>> = () => {
  const stepRoutes = useSsoStepRoutes(steps);

  return <SsoStepNavigation steps={steps}>{stepRoutes}</SsoStepNavigation>;
};
