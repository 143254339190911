import { Text } from '@workos-inc/component-library';
import { FC } from 'react';
import { Img } from '../../../../components/image';
import { StepData } from '../../../../shared/step-navigation/interfaces';
import { StepContent } from '../../../../shared/step-navigation/step-content';

const Component: FC = () => (
  <StepContent confirmLabel="I’ve created a Duo Cloud Application.">
    <Text>
      After configuring the Duo SSO Connection with the IDP of your choice, the
      next step is to create a Cloud Application in Duo. This app will handle
      the connection with Duo.
    </Text>
    <Text>
      Navigate to the Duo Admin Panel and click on Applications on the left
      sidebar. Click on the "Protect an Application" button.
    </Text>

    <Img
      className="my-8"
      height={568}
      src="/images/cca2c337-cdb8-4247-a5d5-1a68f4121994.png"
      width={1046}
    />

    <Text>
      Locate the entry for “Generic Service Provider” with a protection type of
      "2FA with SSO hosted by Duo (Single Sign-On)" in the applications list.
      Click "Protect" to the far-right to start configuring "Generic Service
      Provider".
    </Text>

    <Img
      className="my-8"
      height={719}
      src="/images/8404f0a8-a56f-4c58-b03e-d0ec48ef66b8.png"
      width={1152}
    />
  </StepContent>
);

export const createACloudApplicationInDuo: StepData = {
  title: 'Create a Cloud Application in Duo',
  component: Component,
};
