import { FC } from 'react';
import { Step } from '../../../../shared/step-navigation/interfaces';
import { DsyncStepNavigation } from '../../dsync-step-navigation';
import { useDsyncStepRoutes } from '../../hooks/use-dsync-step-routes';
import { useEnabledDsyncSteps } from '../../hooks/use-enabled-dsync-steps';
import { DsyncFormData } from '../../interfaces/dsync-form-data';
import { generateDsyncSteps } from '../../utils/generate-dsync-steps';
import { setUpAttributeMapping } from './1-step-up-attribute-mapping';
import { allowAccessToGoogleWorkspace } from './2-allow-access-to-google-workspace';

interface GoogleWorkspaceStepsProps {}

export type GoogleWorkspaceFormData = DsyncFormData<'domain'>;

const steps: Step<GoogleWorkspaceFormData>[] = generateDsyncSteps([
  setUpAttributeMapping,
  allowAccessToGoogleWorkspace,
]);

export const GoogleWorkspaceSteps: FC<
  Readonly<GoogleWorkspaceStepsProps>
> = () => {
  const enabledSteps = useEnabledDsyncSteps(steps);
  const stepRoutes = useDsyncStepRoutes<GoogleWorkspaceFormData>(enabledSteps);

  return (
    <DsyncStepNavigation steps={enabledSteps}>{stepRoutes}</DsyncStepNavigation>
  );
};
