import { Text } from '@workos-inc/component-library';
import { FC } from 'react';
import { Card } from '../../../../components/card';
import { FileField } from '../../../../components/fields';
import { Form } from '../../../../components/form';
import { CopyInput } from '../../../../components/inputs';
import { useFormContext } from '../../../../shared/form-data-provider';
import { StepData } from '../../../../shared/step-navigation/interfaces';
import { useStepsStore } from '../../../../shared/step-navigation/steps-store-provider';
import { SsoFormMetadata } from '../../interfaces/sso-form-metadata';
import { useSsoStore } from '../../sso-store-provider';

const Component: FC = () => {
  const { connection } = useSsoStore();

  const { handleNextStep } = useStepsStore();
  const { formData, setFormData } = useFormContext<SsoFormMetadata>();

  return (
    <>
      <Text>Use this ACS URL to configure your Okta settings.</Text>

      <CopyInput
        id="saml-acs-url"
        label="ACS URL"
        value={connection.saml_acs_url}
      />

      <Form
        disabled={!formData.saml_idp_metadata_xml}
        onSubmit={handleNextStep}
      >
        <Card>
          <Text>
            Then, upload your <strong>metadata.xml</strong> file to complete
            configuring your connection.
          </Text>

          <FileField
            accept=".xml"
            id="saml_idp_metadata_xml"
            label="Okta Metadata File"
            name="metadata"
            onUpload={({ file }) => {
              // The `FileField` component renders a "clear" button, and if clicked,
              // calls `onUpload` with a `file` object, but the content is empty string.
              if (!file.content) {
                setFormData({
                  saml_idp_metadata_xml: undefined,
                });
                return;
              }

              setFormData({
                saml_idp_metadata_xml: file.content,
              });
            }}
            value={formData.saml_idp_metadata_xml}
          />
        </Card>
      </Form>
    </>
  );
};

export const setupOktaApplication: StepData = {
  title: 'Setup Okta Application',
  component: Component,
};
