import { Text } from '@workos-inc/component-library';
import classNames from 'classnames';
import { motion } from 'framer-motion';
import { FC } from 'react';
import * as React from 'react';
import { Check, X } from 'react-feather';
import { useNavigate } from 'react-router-dom';
import { StepWithPath } from '../interfaces';
import { useStepsStore } from '../steps-store-provider';

interface SidebarNavItemProps<TFormData> {
  step: StepWithPath<TFormData>;
  stepNumber: number;
  hasError?: boolean;
}

const itemVariants = {
  hidden: { opacity: 0, x: -50 },
  show: { opacity: 1, x: 0 },
};

export const SidebarNavItem = <TFormData,>({
  step,
  stepNumber,
  hasError,
}: SidebarNavItemProps<TFormData>) => {
  const { currentStepNumber } = useStepsStore();
  const navigate = useNavigate();

  const isActive = stepNumber === currentStepNumber;
  const isClickable = stepNumber <= currentStepNumber;

  return (
    <motion.li
      className={classNames(
        {
          'list-none -ml-5 text-gray-lightmode-300': !isActive && isClickable,
          'list-decimal text-primary': isActive && isClickable && !hasError,
          'list-decimal text-gray-lightmode-200': !isClickable && !hasError,
          'list-none -ml-5 text-primary': isActive && isClickable && hasError,
          'list-none -ml-5 text-gray-lightmode-200': !isClickable && hasError,
        },
        'mb-3 align-middle text-sm',
      )}
      variants={itemVariants}
    >
      <div
        className={classNames({
          'cursor-pointer': isClickable,
          'cursor-auto': !isClickable,
        })}
        onClick={() => isClickable && navigate(step.path)}
      >
        <Text inheritColor as="p">
          <StepIcon
            currentStepNumber={currentStepNumber}
            hasError={hasError}
            stepNumber={stepNumber}
          />
          {step.title}
        </Text>
      </div>
    </motion.li>
  );
};

type IconProps = {
  children?: React.ReactNode;
  className?: string;
};

const Icon: FC<Readonly<IconProps>> = ({ children, className }) => (
  <i
    className={classNames(
      'rounded-full w-4 h-4 inline-flex justify-center items-center leading-4 text-center mr-2',
      className,
    )}
  >
    {children}
  </i>
);

type StepIconProps = {
  stepNumber: number;
  hasError?: boolean;
  currentStepNumber: number;
};

const StepIcon: FC<Readonly<StepIconProps>> = ({
  stepNumber,
  hasError,
  currentStepNumber,
}) => {
  const isCompleted = stepNumber < currentStepNumber;

  if (hasError) {
    return (
      <Icon className="bg-red-200 text-red-darken">
        <X data-testid="error-step-icon" size={10} strokeWidth={4} />
      </Icon>
    );
  }

  if (isCompleted) {
    return (
      <Icon className="bg-green-100 text-green-600">
        <Check data-testid="completed-icon" size={10} strokeWidth={4} />
      </Icon>
    );
  }

  return null;
};
