import { FC } from 'react';
import { Step } from '../../../../shared/step-navigation/interfaces';
import { DsyncStepNavigation } from '../../dsync-step-navigation';
import { useDsyncStepRoutes } from '../../hooks/use-dsync-step-routes';
import { DsyncFormData } from '../../interfaces/dsync-form-data';
import { generateDsyncSteps } from '../../utils/generate-dsync-steps';
import { createAnIntegrationSystemUser } from './1-create-an-integration-system-user';
import { createASecurityGroup } from './2-create-a-security-group';
import { addDomainSecurityPoliciesToTheSecurityGroup } from './3-add-domain-security-policies-to-the-security-group';
import { createAndPopulateCustomReports } from './4-create-and-populate-custom-reports';
import { addAnAuthorizedUser } from './5-add-an-authorized-user';
import { getTheRaasEndpoint } from './6-get-the-raas-endpoint';
import { uploadWorkdayEndpointsAndCredentials } from './7-upload-workday-endpoints-and-credentials';
import { mapCustomReportFields } from './8-map-custom-report-fields';

interface WorkdayStepsProps {}

export type WorkdayFormData = DsyncFormData<
  | 'workday_raas_endpoint'
  | 'workday_raas_group_endpoint'
  | 'workday_raas_password'
  | 'workday_raas_username'
>;

const steps: Step<WorkdayFormData>[] = generateDsyncSteps([
  createAnIntegrationSystemUser,
  createASecurityGroup,
  addDomainSecurityPoliciesToTheSecurityGroup,
  createAndPopulateCustomReports,
  addAnAuthorizedUser,
  getTheRaasEndpoint,
  uploadWorkdayEndpointsAndCredentials,
  mapCustomReportFields,
]);

export const WorkdaySteps: FC<Readonly<WorkdayStepsProps>> = () => {
  const stepRoutes = useDsyncStepRoutes<WorkdayFormData>(steps);

  return <DsyncStepNavigation steps={steps}>{stepRoutes}</DsyncStepNavigation>;
};
