import { FC } from 'react';
import { Step } from '../../../../shared/step-navigation';
import { useSsoStepRoutes } from '../../hooks/use-sso-step-routes';
import { MetadataUrlFormFields } from '../../interfaces/metadata-form-fields';
import { SsoStepNavigation } from '../../sso-step-navigation';
import { generateSsoSteps } from '../../utils/generate-sso-steps';
import { createSamlClient } from './1-create-saml-client';
import { samlClientConfiguration } from './2-saml-client-configuration';
import { mapUserAttributes } from './3-map-user-attributes';
import { uploadIdpMetadata } from './4-upload-idp-metadata';

interface KeycloakSamlStepsProps {}

const steps: Step<MetadataUrlFormFields>[] = generateSsoSteps([
  createSamlClient,
  samlClientConfiguration,
  mapUserAttributes,
  uploadIdpMetadata,
]);

export const KeycloakSamlSteps: FC<Readonly<KeycloakSamlStepsProps>> = () => {
  const stepRoutes = useSsoStepRoutes(steps);

  return <SsoStepNavigation steps={steps}>{stepRoutes}</SsoStepNavigation>;
};
