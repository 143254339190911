import { Text } from '@workos-inc/component-library';
import { FC } from 'react';
import { CopyInput } from '../../../../components/inputs';
import { usePortalSession } from '../../../../components/portal-session-provider';
import { StepData } from '../../../../shared/step-navigation/interfaces';
import { StepContent } from '../../../../shared/step-navigation/step-content';
import { useSsoStore } from '../../sso-store-provider';

const Component: FC = () => {
  const { appName } = usePortalSession();
  const { connection } = useSsoStore();

  return (
    <StepContent confirmLabel="I've provided the SP Entity ID to my IdP.">
      <Text>
        The Service Provider Entity ID is a unique value which represents{' '}
        {appName} within your Identity Provider. Provide the value below to your
        Identity Provider.
      </Text>

      <CopyInput
        id="saml-entity-id"
        label="Copy the SP Entity ID"
        value={connection.saml_entity_id}
      />
    </StepContent>
  );
};

export const provideTheSpEntityId: StepData = {
  title: 'Provide the SP Entity ID',
  component: Component,
};
