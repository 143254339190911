import { Text } from '@workos-inc/component-library';
import { FC } from 'react';
import { Img } from '../../../../components/image';
import { StepData } from '../../../../shared/step-navigation/interfaces';
import { StepContent } from '../../../../shared/step-navigation/step-content';
import { ConnectionAttributeMapping } from '../../attribute-mapping';
import { useSsoStore } from '../../sso-store-provider';

const Component: FC = () => {
  const { connection } = useSsoStore();

  return (
    <StepContent confirmLabel="I’ve configured the Application’s parameters.">
      <Text>
        The final step is to confirm that user attributes map to the following
        SAML claims.
      </Text>

      <ConnectionAttributeMapping connectionType={connection.type} />

      <Img
        className="my-8"
        height={1558}
        src="/images/c0f82981-efe8-4da5-a327-367454fc2b69.png"
        width={2816}
      />
    </StepContent>
  );
};

export const configureParameters: StepData = {
  title: 'Configure Parameters',
  component: Component,
};
