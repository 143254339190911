import { FC } from 'react';
import { Step } from '../../../../shared/step-navigation/interfaces';
import { DsyncStepNavigation } from '../../dsync-step-navigation';
import { useDsyncStepRoutes } from '../../hooks/use-dsync-step-routes';
import { useEnabledDsyncSteps } from '../../hooks/use-enabled-dsync-steps';
import { generateDsyncSteps } from '../../utils/generate-dsync-steps';
import { createARipplingScimApplication } from './1-create-a-rippling-scim-application';
import { configureRipplingScimApp } from './2-configure-rippling-scim-app';
import { addSupportedScimAttributes } from './3-add-supported-scim-attributes';
import { configureBearerTokenUsersAndGroups } from './4-configure-bearer-token-users-and-groups';
import { setUpAttributeMapping } from './5-set-up-attribute-mapping';

interface RipplingScimStepsProps {}

const steps: Step[] = generateDsyncSteps([
  createARipplingScimApplication,
  configureRipplingScimApp,
  addSupportedScimAttributes,
  configureBearerTokenUsersAndGroups,
  setUpAttributeMapping,
]);

export const RipplingScimSteps: FC<Readonly<RipplingScimStepsProps>> = () => {
  const enabledSteps = useEnabledDsyncSteps(steps);
  const stepRoutes = useDsyncStepRoutes(enabledSteps);

  return (
    <DsyncStepNavigation steps={enabledSteps}>{stepRoutes}</DsyncStepNavigation>
  );
};
