import { Text } from '@workos-inc/component-library';
import { FC } from 'react';
import { Img } from '../../../../components/image';
import { ExternalLink } from '../../../../components/link';
import { StepData } from '../../../../shared/step-navigation/interfaces';
import { StepContent } from '../../../../shared/step-navigation/step-content';

const Component: FC = () => (
  <StepContent confirmLabel="I've connected Cloudflare with my IdP.">
    <Text>
      Cloudflare SAML is a unique integration in that it sits between a Service
      Provider (application) and the Identity Provider. This allows for
      additional rules to be configured, but also means there are two
      connections that need to be made. The first necessary connection is
      between Cloudflare and the IdP, and the second connection is between the
      Service Provider and Cloudflare.
    </Text>

    <Text>
      First, create the connection between Cloudflare and the Identity Provider.
      Cloudflare Access allows you to connect with any IdP that supports a SAML
      2.0 connection.
    </Text>

    <Text>
      Follow the{' '}
      <ExternalLink
        newTab
        appearance="highlight"
        href="https://developers.cloudflare.com/cloudflare-one/identity/idp-integration/generic-saml"
      >
        documentation from Cloudflare
      </ExternalLink>{' '}
      to configure a SAML application connection between Cloudflare and your
      IdP.
    </Text>

    <Text>
      The one deviation from the CloudFlare documentation is that the SAML
      attributes must include <code>email</code>, <code>firstName</code>,{' '}
      <code>lastName</code>, and <code>id</code>. Email is included by default
      as the "Email attribute name", but you will need to add the other three as
      SAML attributes.
    </Text>

    <Text>
      When setting up the connection, be sure to enter <code>email</code>,{' '}
      <code>firstName</code>, <code>lastName</code>, and <code>id</code> as SAML
      attributes.
    </Text>

    <Img
      className="my-8"
      height={1938}
      src="/images/3ebc7e4d-3a38-40c6-bbd5-b05efafb7d98.png"
      width={2698}
    />

    <Text>
      Save the connection and then click the "Test" button. When successful, you
      will see a success screen including your <code>saml_attributes</code> that
      have been added.
    </Text>

    <Img
      className="my-8"
      height={1810}
      src="/images/89ded345-2112-4758-88c1-75e3904e6450.png"
      width={2698}
    />
  </StepContent>
);

export const connectCloudflareToIdentityProvider: StepData = {
  title: 'Connect Cloudflare to Identity Provider',
  component: Component,
};
