import { Text } from '@workos-inc/component-library';
import { FC } from 'react';
import { Img } from '../../../../components/image';
import { StepData } from '../../../../shared/step-navigation/interfaces';
import { StepContent } from '../../../../shared/step-navigation/step-content';
import { ConnectionAttributeMapping } from '../../attribute-mapping';
import { useSsoStore } from '../../sso-store-provider';

const Component: FC = () => {
  const { connection } = useSsoStore();
  return (
    <StepContent confirmLabel="I’ve configured the User Attributes & Claims.">
      <Text>Click the Edit icon in the top right of the second step.</Text>

      <Img
        className="my-8"
        height={1268}
        src="/images/6155597f-a4b8-4a3c-a7f3-a6e8d4e0865d.png"
        width={2150}
      />

      <Text>Fill in the following Attribute Statements and select "Next":</Text>

      <ConnectionAttributeMapping connectionType={connection.type} />

      <Img
        className="my-8"
        height={1242}
        src="/images/f41b5a7c-1842-4567-9f46-85caa7309176.png"
        width={1774}
      />
    </StepContent>
  );
};

export const userAttributesAndClaims: StepData = {
  title: 'User Attributes & Claims',
  component: Component,
};
