import { FC } from 'react';
import { Step } from '../../../../shared/step-navigation/interfaces';
import { DsyncStepNavigation } from '../../dsync-step-navigation';
import { useDsyncStepRoutes } from '../../hooks/use-dsync-step-routes';
import { useEnabledDsyncSteps } from '../../hooks/use-enabled-dsync-steps';
import { DsyncFormData } from '../../interfaces/dsync-form-data';
import { generateDsyncSteps } from '../../utils/generate-dsync-steps';
import { createAServiceUserInHibob } from './1-create-a-service-user-in-hibob';
import { uploadHibobServiceUserIdAndToken } from './2-upload-hibob-service-user-id-and-token';
import { setUpAttributeMapping } from './3-set-up-attribute-mapping';

interface HibobStepsProps {}

export type HibobFormData = DsyncFormData<
  'bob_api_token' | 'bob_service_user_id'
>;

const steps: Step<HibobFormData>[] = generateDsyncSteps([
  createAServiceUserInHibob,
  uploadHibobServiceUserIdAndToken,
  setUpAttributeMapping,
]);

export const HibobSteps: FC<Readonly<HibobStepsProps>> = () => {
  const enabledSteps = useEnabledDsyncSteps(steps);
  const stepRoutes = useDsyncStepRoutes<HibobFormData>(enabledSteps);

  return (
    <DsyncStepNavigation steps={enabledSteps}>{stepRoutes}</DsyncStepNavigation>
  );
};
