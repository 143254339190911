import { FC } from 'react';
import { Step } from '../../../../shared/step-navigation';
import { useSsoStepRoutes } from '../../hooks/use-sso-step-routes';
import { MetadataUrlFormFields } from '../../interfaces/metadata-form-fields';
import { SsoStepNavigation } from '../../sso-step-navigation';
import { generateSsoSteps } from '../../utils/generate-sso-steps';
import { configureARelyingPartyTrust } from './1-configure-a-relying-party-trust';
import { chooseAccessPolicy } from './2-choose-access-policy';
import { configureClaimsIssuancePolicy } from './3-configure-claims-issuance-policy';
import { uploadMetadataUrl } from './4-upload-metadata-url';

interface AdfsSamlStepsProps {}

const steps: Step<MetadataUrlFormFields>[] = generateSsoSteps([
  configureARelyingPartyTrust,
  chooseAccessPolicy,
  configureClaimsIssuancePolicy,
  uploadMetadataUrl,
]);

export const AdfsSamlSteps: FC<Readonly<AdfsSamlStepsProps>> = () => {
  const stepRoutes = useSsoStepRoutes(steps);

  return <SsoStepNavigation steps={steps}>{stepRoutes}</SsoStepNavigation>;
};
