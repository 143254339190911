import { Text } from '@workos-inc/component-library';
import { FC } from 'react';
import { Img } from '../../../../components/image';
import { StepData } from '../../../../shared/step-navigation/interfaces';
import { StepContent } from '../../../../shared/step-navigation/step-content';

const Component: FC = () => (
  <StepContent confirmLabel="I've configured the attribute mappings.">
    <Text>Expand the "Mappings" section.</Text>

    <Img
      className="my-8"
      height={1620}
      src="/images/8fff45f7-d023-4326-87c4-dba884ade3fb.png"
      width={2000}
    />

    <Text>
      Make sure the group and user attribute mappings are enabled, and are
      mapping the correct fields. The default mapping should work, but your
      specific Azure setup may require you to add a custom mapping.
    </Text>

    <Img
      className="my-8"
      height={1386}
      src="/images/630c1cda-e0a0-4705-90e6-2d026e715272.png"
      width={1976}
    />

    <Text>
      Make sure that you are mapping "objectId" to "externalId" within the
      Attribute Mapping section.
    </Text>

    <Img
      className="my-8"
      height={2154}
      src="/images/2018fe37-eabe-48c7-bfc3-c816e8f8b91c.png"
      width={2878}
    />
  </StepContent>
);

export const setUpAndEnableAttributeMapping: StepData = {
  title: 'Set up and enable attribute mapping',
  component: Component,
};
