import { Title } from '../../components/typography';
import { StepWithPath } from './interfaces';
import { useStepsStore } from './steps-store-provider';

interface StepTitleProps<TFormData> {
  steps: StepWithPath<TFormData>[];
}

export const StepTitle = <TFormData,>({ steps }: StepTitleProps<TFormData>) => {
  const { currentStepNumber } = useStepsStore();

  const currentStep = steps[currentStepNumber - 1];

  if (!currentStep || currentStep?.confirmationStep) {
    return null;
  }

  return (
    <Title className="mb-4">
      Step {currentStepNumber}: {currentStep.title}
    </Title>
  );
};
