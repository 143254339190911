import { FC } from 'react';
import { Step } from '../../../../shared/step-navigation';
import { useSsoStepRoutes } from '../../hooks/use-sso-step-routes';
import { MetadataUrlFormFields } from '../../interfaces/metadata-form-fields';
import { SsoStepNavigation } from '../../sso-step-navigation';
import { generateSsoSteps } from '../../utils/generate-sso-steps';
import { createAndConfigureAClassLinkSamlApplication } from './1-create-and-configure-a-classlink-saml-application';
import { uploadYourIdentityProviderMetadata } from './2-upload-your-identity-provider-metadata';
import { configureYourSamlClaims } from './3-configure-your-saml-claims';

interface ClassLinkSamlStepsProps {}

const steps: Step<MetadataUrlFormFields>[] = generateSsoSteps([
  createAndConfigureAClassLinkSamlApplication,
  uploadYourIdentityProviderMetadata,
  configureYourSamlClaims,
]);

export const ClassLinkSamlSteps: FC<Readonly<ClassLinkSamlStepsProps>> = () => {
  const stepRoutes = useSsoStepRoutes(steps);

  return <SsoStepNavigation steps={steps}>{stepRoutes}</SsoStepNavigation>;
};
