import { Text } from '@workos-inc/component-library';
import { FC } from 'react';
import { Img } from '../../../../components/image';
import { ExternalLink } from '../../../../components/link';
import { StepData } from '../../../../shared/step-navigation/interfaces';
import { StepContent } from '../../../../shared/step-navigation/step-content';

const Component: FC = () => (
  <StepContent confirmLabel="I’ve created a new application in ADP.">
    <Text>
      Login to the{' '}
      <ExternalLink
        newTab
        appearance="highlight"
        aria-label="Link to the ADP Apps Portal"
        href="https://adpapps.adp.com/self-service/projects"
      >
        ADP Partner Self Service Portal
      </ExternalLink>
    </Text>

    <Text>
      From this page there are two environments to select from: Development and
      Production. Please use the environment that best suits your use-case for
      this SSO connection.
    </Text>

    <Img
      className="my-8"
      height={841}
      src="/images/e9db5351-dcb5-4f73-b917-4675071fc118.png"
      width={1263}
    />

    <Text>In the selected environment select "Create New Project".</Text>

    <Img
      className="my-8"
      height={639}
      src="/images/e574629b-e20c-4265-b46e-4c0e0504f6ea.png"
      width={881}
    />

    <Text>
      Give the project a meaningful name to designate the SSO connection. There
      is no need to add a description.
    </Text>

    <Img
      className="my-8"
      height={626}
      src="/images/f26fd256-7686-4870-9abe-653388059978.png"
      width={884}
    />

    <Text>
      Make the selections "US Marketplace" and "ADP Workforce Now" respectively
      for the next selections and then click "Next".
    </Text>

    <Img
      className="my-8"
      height={796}
      src="/images/d027dd48-037a-4347-953c-67f368c69cb0.png"
      width={891}
    />

    <Text>
      Finally, select "Other" for the use case that best describes your
      application and click "Submit".
    </Text>
  </StepContent>
);

export const createAProjectInTheAdpAppsPortal: StepData = {
  title: 'Create a Project in the ADP Apps Portal',
  component: Component,
};
