import { Text } from '@workos-inc/component-library';
import { FC } from 'react';
import { Img } from '../../../../components/image';
import { StepData } from '../../../../shared/step-navigation/interfaces';
import { StepContent } from '../../../../shared/step-navigation/step-content';
import { PeopleHrFormData } from './people-hr-steps';

const Component: FC = () => (
  <StepContent confirmLabel="I've generated my Access People HR API key.">
    <Text>
      If you are an admin of your Access People HR instance, log in and select
      the "Settings" page from the side bar menu. Then, select "API" from the
      Settings side bar menu. On the API Key Management page, select the plus
      sign to add a new API Key.
    </Text>

    <Img
      className="my-8"
      height={1606}
      src="/images/66251774-b635-418b-9521-7a62836a3ee5.png"
      width={2728}
    />

    <Text>
      In the API Key Generator, give the API Key a descriptive name. Under
      "Application", select "Employee".
    </Text>

    <Img
      className="my-8"
      height={1606}
      src="/images/0a92af08-8499-41a7-9fda-2c18da21f6ce.png"
      width={2728}
    />

    <Text>
      On the Select Permissions page, check only "Get All Employee Detail" and
      then select "Save".
    </Text>

    <Img
      className="my-8"
      height={1606}
      src="/images/a286675a-81b3-48f0-9c92-61595ad7e080.png"
      width={2728}
    />

    <Text>On the API Key Generator page, select "Save".</Text>

    <Img
      className="my-8"
      height={1606}
      src="/images/49cfbc5f-4a20-475c-98fb-e1454dd32ce0.png"
      width={2728}
    />
    <Text>Copy and save the API key - you will input it in the next step.</Text>

    <Img
      className="my-8"
      height={1606}
      src="/images/99596b83-7124-448b-a7cd-41e342132fcb.png"
      width={2728}
    />
  </StepContent>
);

export const generateApiKeyInAccessPeopleHr: StepData<PeopleHrFormData> = {
  title: 'Generate API Key in Access People HR',
  component: Component,
};
