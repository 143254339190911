import { Text } from '@workos-inc/component-library';
import { FC } from 'react';
import { Img } from '../../../../components/image';
import { StepData } from '../../../../shared/step-navigation/interfaces';
import { StepContent } from '../../../../shared/step-navigation/step-content';

const Component: FC = () => (
  <StepContent confirmLabel="I've granted access to profiles who should access the Connected App.">
    <Text>
      Select "Manage Profiles" section in the app view. Grant access to the
      appropriate profiles that should have access to the application.
    </Text>
    <Img
      className="my-8"
      height={812}
      src="/images/84b366ef-1643-421c-95f7-ef6ed049b3f8.png"
      width={1440}
    />
    <Text>
      The image below shows an example of a successful configuration allowing
      all "End User" profiles to access the Connected App:
    </Text>
    <Img
      className="my-8"
      height={777}
      src="/images/6ecdeed1-bcca-40ea-af4d-624443efcfd8.png"
      width={1432}
    />
    <Text>Press "Save".</Text>
  </StepContent>
);

export const addUserProfiles: StepData = {
  title: 'Add User Profiles',
  component: Component,
};
