import { Text } from '@workos-inc/component-library';
import { FC } from 'react';
import { Img } from '../../../../components/image';
import { CopyInput } from '../../../../components/inputs';
import { StepData } from '../../../../shared/step-navigation/interfaces';
import { StepContent } from '../../../../shared/step-navigation/step-content';
import { useDsyncStore } from '../../dsync-store-provider';

const Component: FC = () => {
  const { directory } = useDsyncStore();

  return (
    <StepContent confirmLabel="I’ve confirmed, activated and saved the configuration settings.">
      <Text>Select "Identity Management" from the top navigation menu.</Text>

      <Img
        className="my-8"
        height={2560}
        src="/images/29f389d8-0ccb-4402-8d75-0a1ba135051d.png"
        width={4732}
      />

      <Text>
        Scroll down to the "Configuration settings" section. Make sure SCIM 2.0
        is selected as the SCIM version.
      </Text>

      <Text>Copy and paste the endpoint into the "Base URL" field.</Text>

      <CopyInput
        id="directory-endpoint"
        label="Copy this Endpoint"
        value={directory.endpoint}
      />

      <Text>Copy and paste the Bearer Token into the "Token Key" field.</Text>

      <CopyInput
        id="directory-bearer-token"
        label="Copy this Bearer Token"
        type="password"
        value={directory.bearerToken}
      />

      <Text>
        Next, test the connection to confirm the configuration settings.
      </Text>

      <Img
        className="my-8"
        height={1039}
        src="/images/b5761f11-54d4-41ef-99ab-26318a0c44a6.png"
        width={1920}
      />

      <Text>
        After you receive a success message for the configuration, make sure the
        Group Management toggle is "On", and then activate the settings.
      </Text>

      <Img
        className="my-8"
        height={1039}
        src="/images/8e35cb63-a808-43fc-bd45-398d87c9726c.png"
        width={1920}
      />

      <Text>
        After the activation step is successful, save the configuration.
      </Text>

      <Img
        className="my-8"
        height={1039}
        src="/images/63c38760-e960-4554-8f07-33c27e1ca375.png"
        width={1920}
      />
    </StepContent>
  );
};

export const configureJumpCloudScim: StepData = {
  title: 'Configure JumpCloud SCIM',
  component: Component,
};
