import { Text } from '@workos-inc/component-library';
import { FC } from 'react';
import { Img } from '../../../../components/image';
import { StepData } from '../../../../shared/step-navigation/interfaces';
import { metadataUrlFormKeys } from '../../constants/metadata-form-keys';
import { MetadataUrlFormFields } from '../../interfaces/metadata-form-fields';
import { MetadataUrlStep } from '../../metadata-step';

const Component: FC = () => (
  <MetadataUrlStep
    idpEntityIdPlaceholder="https://sso.adfs.com/12345abc..."
    idpSsoUrlPlaceholder="https://adfs.test.com/adfs/ls..."
    metadataUrlPlaceholder="https://SERVER/federationmetadata/2007-06/federationmetadata.xml"
  >
    <Text>
      Next you will want to obtain the Metadata URL from your ADFS server. ADFS
      publishes its metadata to a standard URL by default:
      (https://SERVER/federationmetadata/2007-06/federationmetadata.xml) where
      "SERVER" is your federation service FQDN. You can also find your ADFS
      Federation Metadata URL through the AD FS Management in "AD FS -{'>'}{' '}
      Service -{'>'} Endpoints" and navigate to the Metadata section.
    </Text>

    <Img
      className="my-8"
      height={853}
      src="/images/f9c91a23-847c-4032-9bbb-888d071db27d.png"
      width={1186}
    />

    <Text>
      Upload the Metadata URL and the connection will be verified and linked!
    </Text>
  </MetadataUrlStep>
);

export const uploadMetadataUrl: StepData<MetadataUrlFormFields> = {
  title: 'Upload Metadata URL',
  component: Component,
  formKeys: metadataUrlFormKeys,
};
