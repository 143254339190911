import { Text } from '@workos-inc/component-library';
import { FC } from 'react';
import { Img } from '../../../../components/image';
import { StepData } from '../../../../shared/step-navigation/interfaces';
import { metadataUrlFormKeys } from '../../constants/metadata-form-keys';
import { MetadataUrlFormFields } from '../../interfaces/metadata-form-fields';
import { MetadataUrlStep } from '../../metadata-step';

const Component: FC = () => (
  <MetadataUrlStep
    idpEntityIdPlaceholder="https://sts.windows.net/123-abc/"
    idpSsoUrlPlaceholder="https://login.microsoftonline.com/123abc/saml2"
    metadataUrlPlaceholder="https://login.microsoftonline.com/123-abc/..."
  >
    <Text>
      Navigate down to Section 3 of the "Single Sign On" page, to "SAML Signing
      Certificate". Copy the URL provided in "App Federation Metadata URL".
    </Text>

    <Img
      className="my-8"
      height={1392}
      src="/images/c4ee0b27-ddd7-4aab-96c0-ced1019b4cd7.png"
      width={2176}
    />

    <Text>Provide the Metadata URL you copied below.</Text>
  </MetadataUrlStep>
);

export const uploadIdpMetadata: StepData<MetadataUrlFormFields> = {
  title: 'Upload IdP Metadata',
  component: Component,
  formKeys: metadataUrlFormKeys,
};
