import { FC } from 'react';
import { Step } from '../../../../shared/step-navigation';
import { useSsoStepRoutes } from '../../hooks/use-sso-step-routes';
import { MetadataUrlFormFields } from '../../interfaces/metadata-form-fields';
import { SsoStepNavigation } from '../../sso-step-navigation';
import { generateSsoSteps } from '../../utils/generate-sso-steps';
import { createAndConfigureASimpleSamlPhpSamlApplication } from './1-create-and-configure-a-simple-saml-php-saml-application';
import { uploadIdentityProviderMetadata } from './2-upload-identity-provider-metadata';
import { configureYourSamlClaims } from './3-configure-your-saml-claims';

interface SimpleSamlPhpSamlStepsProps {}

const steps: Step<MetadataUrlFormFields>[] = generateSsoSteps([
  createAndConfigureASimpleSamlPhpSamlApplication,
  uploadIdentityProviderMetadata,
  configureYourSamlClaims,
]);

export const SimpleSamlPhpSamlSteps: FC<
  Readonly<SimpleSamlPhpSamlStepsProps>
> = () => {
  const stepRoutes = useSsoStepRoutes(steps);

  return <SsoStepNavigation steps={steps}>{stepRoutes}</SsoStepNavigation>;
};
