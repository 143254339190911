import { FC } from 'react';
import { Step } from '../../../../shared/step-navigation';
import { useSsoStepRoutes } from '../../hooks/use-sso-step-routes';
import { MetadataUrlFormFields } from '../../interfaces/metadata-form-fields';
import { SsoStepNavigation } from '../../sso-step-navigation';
import { generateSsoSteps } from '../../utils/generate-sso-steps';
import { createASamlApplication } from './1-create-a-saml-application';
import { configureSamlClaims } from './2-configure-saml-claims';
import { basicSamlConfiguration } from './3-basic-saml-configuration';

interface OracleSamlStepsProps {}

const steps: Step<MetadataUrlFormFields>[] = generateSsoSteps([
  createASamlApplication,
  configureSamlClaims,
  basicSamlConfiguration,
]);

export const OracleSamlSteps: FC<Readonly<OracleSamlStepsProps>> = () => {
  const stepRoutes = useSsoStepRoutes(steps);

  return <SsoStepNavigation steps={steps}>{stepRoutes}</SsoStepNavigation>;
};
