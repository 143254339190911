import { FC } from 'react';
import { Step } from '../../../../shared/step-navigation/interfaces';
import { DsyncStepNavigation } from '../../dsync-step-navigation';
import { useDsyncStepRoutes } from '../../hooks/use-dsync-step-routes';
import { useEnabledDsyncSteps } from '../../hooks/use-enabled-dsync-steps';
import { generateDsyncSteps } from '../../utils/generate-dsync-steps';
import { selectOrCreateOneLoginApplication } from './1-select-or-create-one-login-application';
import { configureOneLoginScim } from './2-configure-one-login-scim';
import { assignUsersToYourApplication } from './3-assign-users-to-your-application';
import { assignGroupsToYourApplication } from './4-assign-groups-to-your-application';
import { setUpAttributeMapping } from './5-set-up-attribute-mapping';

interface OneLoginScimStepsProps {}

const steps: Step[] = generateDsyncSteps([
  selectOrCreateOneLoginApplication,
  configureOneLoginScim,
  assignUsersToYourApplication,
  assignGroupsToYourApplication,
  setUpAttributeMapping,
]);

export const OneLoginScimSteps: FC<Readonly<OneLoginScimStepsProps>> = () => {
  const enabledSteps = useEnabledDsyncSteps(steps);
  const stepRoutes = useDsyncStepRoutes(enabledSteps);

  return (
    <DsyncStepNavigation steps={enabledSteps}>{stepRoutes}</DsyncStepNavigation>
  );
};
