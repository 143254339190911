import { Text } from '@workos-inc/component-library';
import { FC } from 'react';
import { Ol } from '../../../../../components/typography';
import { Img } from '../../../../components/image';
import { StepData } from '../../../../shared/step-navigation/interfaces';
import { metadataUrlFormKeys } from '../../constants/metadata-form-keys';
import { MetadataUrlFormFields } from '../../interfaces/metadata-form-fields';
import { MetadataUrlStep } from '../../metadata-step';

const Component: FC = () => (
  <MetadataUrlStep
    idpEntityIdPlaceholder="http://www.okta.com/exkgq2c19CUpt2Brr46"
    idpSsoUrlPlaceholder="http://www.okta.com/exkgq2c19CUpt2Brr46"
    metadataUrlPlaceholder="https://"
  >
    <Text>
      The final step for implementing SAML SSO requires sharing your identity
      provider's metadata with the application.
    </Text>

    <Text as="h2" size="large" weight="medium">
      URL Metadata Configuration
    </Text>

    <Ol className="my-4">
      <li>Click on the "Sign On" tab of the SAML app you just created.</li>
      <li>
        Click the "Actions" dropdown for the correct certificate and select
        “View IdP Metadata."
      </li>
      <li>A separate tab will open. Copy the link and paste it below.</li>
    </Ol>

    <Img
      className="my-8"
      height={1048}
      src="/images/e98e855e-6733-4f6e-96da-5c993cebd21b.png"
      width={990}
    />
  </MetadataUrlStep>
);

export const uploadIdentityProviderMetadata: StepData<MetadataUrlFormFields> = {
  title: 'Upload Identity Provider Metadata',
  formKeys: metadataUrlFormKeys,
  component: Component,
};
