import { FC } from 'react';
import { Step } from '../../../../shared/step-navigation/interfaces';
import { DsyncStepNavigation } from '../../dsync-step-navigation';
import { useDsyncStepRoutes } from '../../hooks/use-dsync-step-routes';
import { useEnabledDsyncSteps } from '../../hooks/use-enabled-dsync-steps';
import { DsyncFormData } from '../../interfaces/dsync-form-data';
import { generateDsyncSteps } from '../../utils/generate-dsync-steps';
import { uploadCezanneHrClientIdAndSecret } from './1-upload-cezanne-hr-client-id-and-secret';
import { setUpAttributeMapping } from './2-set-up-attribute-mapping';

interface CezanneHrStepsProps {}

export type CezanneHrFormData = DsyncFormData<
  'cezanneHrClientId' | 'cezanneHrClientSecret'
>;

const steps: Step<CezanneHrFormData>[] = generateDsyncSteps([
  uploadCezanneHrClientIdAndSecret,
  setUpAttributeMapping,
]);

export const CezanneHrSteps: FC<Readonly<CezanneHrStepsProps>> = () => {
  const enabledSteps = useEnabledDsyncSteps(steps);
  const stepRoutes = useDsyncStepRoutes<CezanneHrFormData>(enabledSteps);

  return (
    <DsyncStepNavigation steps={enabledSteps}>{stepRoutes}</DsyncStepNavigation>
  );
};
