import { Text } from '@workos-inc/component-library';
import { FC } from 'react';
import { Img } from '../../../../components/image';
import { CopyInput } from '../../../../components/inputs';
import { StepData } from '../../../../shared/step-navigation/interfaces';
import { StepContent } from '../../../../shared/step-navigation/step-content';
import { useDsyncStore } from '../../dsync-store-provider';

const Component: FC = () => {
  const { directory } = useDsyncStore();

  return (
    <StepContent confirmLabel="I’ve confirmed, activated and saved the configuration settings.">
      <Text>
        Within the SCIM Application, select the "Configuration" tab on the left.
      </Text>

      <Text>Copy and paste the endpoint below into the "SCIM Base URL"</Text>

      <CopyInput
        id="directory-endpoint"
        label="Copy this Endpoint"
        value={directory.endpoint}
      />

      <Text>
        Then, copy and paste the Bearer Token below into the "SCIM Bearer Token"
        field.
      </Text>

      <CopyInput
        id="directory-bearer-token"
        label="Copy this Bearer Token"
        type="password"
        value={directory.bearerToken}
      />

      <Text>Hit "Enable" under "API Status" and then hit "Save".</Text>

      <Img
        className="my-8"
        height={1868}
        src="/images/54c23dd5-1a05-448e-8a1b-255dc8a95b1b.png"
        width={2062}
      />

      <Text>
        Select the "Provisioning" tab on the left. Check the "Enable
        provisioning" box and hit "Save".
      </Text>

      <Img
        className="my-8"
        height={1278}
        src="/images/94f76ad5-30ad-4d65-a3c7-6a18ec0e0683.png"
        width={2040}
      />

      <Text>
        Select the "Parameters" tab on the left. Then select "Groups".
      </Text>

      <Img
        className="my-8"
        height={1426}
        src="/images/54c10855-69e5-4484-8ccd-44f2fe89baf3.png"
        width={2122}
      />

      <Text>
        In the modal that pops up, check the box next to "Include in User
        Provisioning" and hit "Save".
      </Text>

      <Img
        className="my-8"
        height={1426}
        src="/images/09795f59-f7c0-4b04-97d5-9044fb05d987.png"
        width={2122}
      />
    </StepContent>
  );
};

export const configureOneLoginScim: StepData = {
  title: 'Configure OneLogin SCIM',
  component: Component,
};
