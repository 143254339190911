import { Text } from '@workos-inc/component-library';
import { FC } from 'react';
import { Img } from '../../../../components/image';
import { StepData } from '../../../../shared/step-navigation/interfaces';
import { StepContent } from '../../../../shared/step-navigation/step-content';

const Component: FC = () => (
  <StepContent confirmLabel="I've finished configuring the User Access.">
    <Text>
      In the created SAML app's landing page, select the "User Access Section".
    </Text>

    <Img
      className="my-8"
      height={1034}
      src="/images/8f314d73-d51b-4935-accb-dc019f42967f.png"
      width={1271}
    />

    <Text>
      Turn this service ON for the correct organizational units in your Google
      Directory setup. Save any changes. Note that Google may take up to 24
      hours to propagate these changes, and the connection may be inactive until
      the changes have propagated.
    </Text>

    <Img
      className="my-8"
      height={1034}
      src="/images/bbfbe286-485d-471c-a799-fd816c34d0ac.png"
      width={1271}
    />
  </StepContent>
);

export const configureUserAccess: StepData = {
  title: 'Configure User Access',
  component: Component,
};
