import { FC } from 'react';
import { Step } from '../../../../shared/step-navigation/interfaces';
import { DsyncStepNavigation } from '../../dsync-step-navigation';
import { useDsyncStepRoutes } from '../../hooks/use-dsync-step-routes';
import { useEnabledDsyncSteps } from '../../hooks/use-enabled-dsync-steps';
import { DsyncFormData } from '../../interfaces/dsync-form-data';
import { generateDsyncSteps } from '../../utils/generate-dsync-steps';
import { generateApiKeyInBreatheHr } from './1-generate-api-key-in-breathe-hr';
import { uploadBreatheHrApiKey } from './2-upload-breathe-hr-api-key';
import { setUpAttributeMapping } from './3-set-up-attribute-mapping';

interface BreatheHrStepsProps {}

export type BreatheHrFormData = DsyncFormData<'breatheHrApiKey'>;

const steps: Step<BreatheHrFormData>[] = generateDsyncSteps([
  generateApiKeyInBreatheHr,
  uploadBreatheHrApiKey,
  setUpAttributeMapping,
]);

export const BreatheHrSteps: FC<Readonly<BreatheHrStepsProps>> = () => {
  const enabledSteps = useEnabledDsyncSteps(steps);
  const stepRoutes = useDsyncStepRoutes<BreatheHrFormData>(enabledSteps);

  return (
    <DsyncStepNavigation steps={enabledSteps}>{stepRoutes}</DsyncStepNavigation>
  );
};
