import { FC } from 'react';
import { Step } from '../../../../shared/step-navigation';
import { useSsoStepRoutes } from '../../hooks/use-sso-step-routes';
import { MetadataUrlFormFields } from '../../interfaces/metadata-form-fields';
import { SsoStepNavigation } from '../../sso-step-navigation';
import { generateSsoSteps } from '../../utils/generate-sso-steps';
import { createSamlIntegration } from './1-create-saml-integration';
import { submitAcsUrlAndEntityId } from './2-submit-acs-url-and-entity-id';
import { configureAttributeStatements } from './3-configure-attribute-statements';
import { uploadMetadataFromPingOne } from './4-upload-metadata-from-pingone';

interface PingOneSamlStepsProps {}

const steps: Step<MetadataUrlFormFields>[] = generateSsoSteps([
  createSamlIntegration,
  submitAcsUrlAndEntityId,
  configureAttributeStatements,
  uploadMetadataFromPingOne,
]);

export const PingOneSamlSteps: FC<Readonly<PingOneSamlStepsProps>> = () => {
  const stepRoutes = useSsoStepRoutes(steps);

  return <SsoStepNavigation steps={steps}>{stepRoutes}</SsoStepNavigation>;
};
