import { Alert, Text } from '@workos-inc/component-library';
import { unreachable } from '@workos-inc/standard';
import { FC } from 'react';
import {
  OAuthSessionState,
  OidcSessionState,
  SamlSessionState,
} from '../../../../../graphql/generated';
import { usePortalSession } from '../../../../components/portal-session-provider';

export interface RecentEventFlashMessageProps {}

export const RecentEventFlashMessage: FC<
  Readonly<RecentEventFlashMessageProps>
> = () => {
  const { recentConnectionSession } = usePortalSession();

  if (!recentConnectionSession) {
    return null;
  }
  switch (recentConnectionSession.state) {
    case OAuthSessionState.Authorized:
    case OAuthSessionState.Successful:
    case OidcSessionState.Authorized:
    case OidcSessionState.Successful:
    case SamlSessionState.Authorized:
    case SamlSessionState.Successful:
      return (
        <Alert appearance="green">
          <Text inheritColor as="p">
            Your Single Sign-On test was successful. Your connection is ready
            for use.
          </Text>
        </Alert>
      );
    case OAuthSessionState.Failed:
    case OidcSessionState.Failed:
    case SamlSessionState.Failed:
      return (
        <Alert appearance="red">
          <Text inheritColor as="p">
            There was a problem with your Single Sign-On test. Learn more about
            how to resolve this issue.
          </Text>
        </Alert>
      );
    case OAuthSessionState.Started:
    case OidcSessionState.Started:
    case SamlSessionState.Started:
      return null;
    default:
      unreachable(recentConnectionSession);
  }
};
