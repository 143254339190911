import { FC } from 'react';
import { Step } from '../../../../shared/step-navigation';
import { useSsoStepRoutes } from '../../hooks/use-sso-step-routes';
import { MetadataXmlFormFields } from '../../interfaces/metadata-form-fields';
import { SsoStepNavigation } from '../../sso-step-navigation';
import { generateSsoSteps } from '../../utils/generate-sso-steps';
import { addCustomSamlApplication } from './1-add-custom-saml-application';
import { enterDetailsForYourCustomApp } from './2-enter-details-for-your-custom-app';
import { uploadIdentityProviderMetadata } from './3-upload-indentity-provider-metadata';
import { enterServiceProviderDetails } from './4-enter-service-provider-details';
import { configureAttributeMapping } from './5-configure-attribute-mapping';
import { configureUserAccess } from './6-configure-user-access';

interface GoogleSamlStepsProps {}

const steps: Step<MetadataXmlFormFields>[] = generateSsoSteps([
  addCustomSamlApplication,
  enterDetailsForYourCustomApp,
  uploadIdentityProviderMetadata,
  enterServiceProviderDetails,
  configureAttributeMapping,
  configureUserAccess,
]);

export const GoogleSamlSteps: FC<Readonly<GoogleSamlStepsProps>> = () => {
  const stepRoutes = useSsoStepRoutes(steps);

  return <SsoStepNavigation steps={steps}>{stepRoutes}</SsoStepNavigation>;
};
