import { Text } from '@workos-inc/component-library';
import { FC } from 'react';
import { Img } from '../../../../components/image';
import { CopyInput } from '../../../../components/inputs';
import { StepData } from '../../../../shared/step-navigation/interfaces';
import { StepContent } from '../../../../shared/step-navigation/step-content';
import { useSsoStore } from '../../sso-store-provider';

const Component: FC = () => {
  const { connection } = useSsoStore();

  return (
    <StepContent
      confirmLabel="I’ve configured the Assertion Consumer Service (ACS) URL and
    Entity ID."
    >
      <Text>
        Under the "Set up LastPass" section of the "Configure app" modal, input
        the ACS URL below under "ACS". Then click on "Advanced Settings".
      </Text>

      <CopyInput
        id="acs-url"
        label="Copy this ACS URL"
        value={connection.saml_acs_url}
      />

      <Img
        height={1392}
        src="/images/01e69cb0-8c93-474f-ad15-6cde111ac699.png"
        width={2278}
      />

      <Text>
        Under "Entity ID", input the SP Entity ID below. Next, under "SAML
        signature method", select "SHA256".
      </Text>

      <CopyInput
        id="sp-entity-id"
        label="Copy this SP Entity ID"
        value={connection.saml_entity_id}
      />

      <Img
        height={1830}
        src="/images/4b80975b-c0bb-4efd-80dd-3a3c52836ec5.png"
        width={2584}
      />

      <Text>
        Under "Signing and encryption", ensure that you have at least selected
        "Sign assertion". Then, click on "Add SAML attribute".
      </Text>

      <Img
        height={1666}
        src="/images/0e2a022f-444b-468b-91ad-cb2078142425.png"
        width={2424}
      />
    </StepContent>
  );
};

export const submitAcsUrlAndEntityId: StepData = {
  title: 'Submit ACS URL and Entity ID',
  component: Component,
};
