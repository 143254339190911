import { Text } from '@workos-inc/component-library';
import { FC } from 'react';
import { CopyInput } from '../../../../components/inputs';
import { ExternalLink } from '../../../../components/link';
import { StepData } from '../../../../shared/step-navigation/interfaces';
import { StepContent } from '../../../../shared/step-navigation/step-content';
import { useSsoStore } from '../../sso-store-provider';

const Component: FC = () => {
  const { connection } = useSsoStore();

  return (
    <StepContent confirmLabel="I've created and configured a SAML application.">
      <Text>
        Follow the{' '}
        <ExternalLink
          newTab
          appearance="highlight"
          href="https://simplesamlphp.org/docs/latest/simplesamlphp-idp.html"
        >
          SimpleSAMLphp documentation
        </ExternalLink>{' '}
        to set up SimpleSAMLphp as an Identity Provider and add a new Service
        Provider.
      </Text>

      <Text>
        Copy and paste the ACS URL and SP Entity ID into the corresponding
        fields for Service Provider configuration. You can find more on how to
        structure this under "Adding SPs to the IdP" in the SimpleSAMLphp
        documentation linked above.
      </Text>

      <CopyInput
        id="saml-acs-url"
        label="Copy this ACS URL"
        value={connection.saml_acs_url}
      />

      <CopyInput
        id="saml-entity-id"
        label="Copy this SP Entity ID"
        value={connection.saml_entity_id}
      />

      <Text>
        The necessary SP metadata can also be found in the SP metadata URL
        provided below.
      </Text>

      <CopyInput
        id="saml-sp-metadata-url"
        label="Copy this URL/XML"
        value={connection.saml_sp_metadata_url}
      />
    </StepContent>
  );
};

export const createAndConfigureASimpleSamlPhpSamlApplication: StepData = {
  title: 'Create and configure a SimpleSAMLphp SAML Application',
  component: Component,
};
