import { InputGroup } from '@workos-inc/component-library';
import { ChangeEvent, FC, useState } from 'react';
import { Card } from '../../../../components/card';
import { Form } from '../../../../components/form';
import { StepData } from '../../../../shared/step-navigation/interfaces';
import { useStepsStore } from '../../../../shared/step-navigation/steps-store-provider';
import { graphql } from '../../../../utils/graphql';

const Component: FC = () => {
  const { handleNextStep } = useStepsStore();

  const [identityProvider, setIdentityProvider] = useState('');

  const handleIdPSubmit = (): void => {
    void graphql().RequestIdentityProvider({
      identityProvider,
      pageRequestedFrom: 'Generic SAML',
    });

    void handleNextStep();
  };

  return (
    <Card>
      <Form isInline disabled={!identityProvider} onSubmit={handleIdPSubmit}>
        <InputGroup
          autoFocus
          id="identity_provider"
          label="Which Identity Provider are you connecting to?"
          name="identity_provider"
          onChange={(event: ChangeEvent<HTMLInputElement>) =>
            setIdentityProvider(event.target.value)
          }
          placeholder="e.g. Okta, Azure AD, OneLogin..."
          value={identityProvider}
        />
      </Form>
    </Card>
  );
};

export const provideIdpInformation: StepData = {
  title: 'Provide IdP Information',
  component: Component,
};
