import { Text } from '@workos-inc/component-library';
import { FC } from 'react';
import { Img } from '../../../../components/image';
import { CopyInput } from '../../../../components/inputs';
import { StepData } from '../../../../shared/step-navigation/interfaces';
import { StepContent } from '../../../../shared/step-navigation/step-content';
import { useSsoStore } from '../../sso-store-provider';

const Component: FC = () => {
  const { connection } = useSsoStore();
  return (
    <StepContent confirmLabel="I’ve finished configuring the ACS URL.">
      <Text>
        Next, copy the ACS URL below and paste it in the Generic Service
        Provider settings towards the bottom of the page under "Assertion
        Consumer Service (ACS) URL"
      </Text>

      <CopyInput
        id="saml-acs-url"
        label="Copy this ACS URL"
        value={connection.saml_acs_url}
      />

      <Img
        className="my-8"
        height={1040}
        src="/images/be71f9be-752a-4aed-8b1d-b2544745babf.png"
        width={2188}
      />

      <Text>
        You may leave the Single Logout URL, Service Provider Login URL, and
        Default Relay State fields empty.
      </Text>
    </StepContent>
  );
};

export const enterTheAcsUrlInDuo: StepData = {
  title: 'Enter the ACS URL in Duo',
  component: Component,
};
