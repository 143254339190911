import { Text } from '@workos-inc/component-library';
import { FC } from 'react';
import { Img } from '../../../../components/image';
import { StepData } from '../../../../shared/step-navigation/interfaces';
import { StepContent } from '../../../../shared/step-navigation/step-content';

const Component: FC = () => (
  <StepContent confirmLabel="I’ve finished configuring, and have activated, the Outbound Provisioning Channel.">
    <Text>
      On the Attribute Mapping page, configure the mapping of attributes in the
      datastore to the SCIM attributes. The exact configuration will depend on
      the specific setup in each unique situation. For this PingDirectory LDAP
      example, the default settings are used. When finished, Click "Next".
    </Text>

    <Img
      className="my-8"
      height={3912}
      src="/images/7e5a4522-77e1-4e06-8808-249edccffdd7.png"
      width={2468}
    />

    <Text>
      On the Activation {'&'} Summary page, check that the settings are complete
      and the toggle for <code>Channel Status</code> is set to{' '}
      <code>Active</code> and select "Done".
    </Text>

    <Img
      className="my-8"
      height={2162}
      src="/images/2862523c-a733-4a5e-b547-6ec5cb216beb.png"
      width={2468}
    />

    <Text>
      When you are directed back to the Manage Channels page, select "Done".
    </Text>

    <Img
      className="my-8"
      height={854}
      src="/images/41f954ef-0a75-4806-b6b4-53629c9a9e20.png"
      width={2468}
    />

    <Text>
      You’re then directed to the Outbound Provisioning page. Select "Next".
    </Text>

    <Img
      className="my-8"
      height={854}
      src="/images/42690024-97be-42ac-867d-e45403411d78.png"
      width={2468}
    />
  </StepContent>
);

export const configureAttributeMappingForYourPingFederateApplication: StepData =
  {
    title: 'Configure Attribute Mapping for your PingFederate Application',
    component: Component,
  };
