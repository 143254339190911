import { Text } from '@workos-inc/component-library';
import { FC } from 'react';
import { StepData } from '../../../../shared/step-navigation/interfaces';
import { metadataUrlFormKeys } from '../../constants/metadata-form-keys';
import { MetadataUrlFormFields } from '../../interfaces/metadata-form-fields';
import { MetadataUrlStep } from '../../metadata-step';

const Component: FC = () => (
  <MetadataUrlStep
    idpEntityIdPlaceholder="https://mysamlserver.com/abc123..."
    idpSsoUrlPlaceholder="https://mysamlserver.com/..."
    metadataUrlPlaceholder="https://"
  >
    <Text>
      To connect with your SAML provider, please upload your identity provider's
      metadata.
    </Text>

    <Text as="h2" size="large" weight="medium">
      URL Metadata Configuration
    </Text>

    <Text>
      Upload the IdP metadata URL from your SAML settings. Some Generic SAML
      providers might not be able to provide the IdP Metadata URL. In these
      cases, you'll want to manually configure the connection.
    </Text>
  </MetadataUrlStep>
);

export const uploadYourIdentityProviderMetadata: StepData<MetadataUrlFormFields> =
  {
    title: 'Upload your Identity Provider Metadata',
    component: Component,
    formKeys: metadataUrlFormKeys,
  };
