import { Text } from '@workos-inc/component-library';
import { FC } from 'react';
import { Img } from '../../../../components/image';
import { StepData } from '../../../../shared/step-navigation/interfaces';
import { StepContent } from '../../../../shared/step-navigation/step-content';
import { ConnectionAttributeMapping } from '../../attribute-mapping';
import { useSsoStore } from '../../sso-store-provider';

const Component: FC = () => {
  const { connection } = useSsoStore();

  return (
    <StepContent
      confirmLabel="I’ve finished configuring the Attribute Statements
    and clicked “activate” and “continue“."
    >
      <Text>
        In the “User Attributes” section, click on the "add attribute" button to
        begin adding new user attributes. Add in the below attribute statements.
      </Text>

      <Img
        className="my-8"
        height={1306}
        src="/images/47100088-c7b5-4352-96e1-9709f7808b6b.png"
        width={2126}
      />

      <ConnectionAttributeMapping connectionType={connection.type} />

      <Text>
        Once you’ve finished, select “activate” and then click on the “continue”
        button on the subsequent pop-up window.
      </Text>
    </StepContent>
  );
};

export const configureAttributeStatements: StepData = {
  title: 'Configure Attribute Statements',
  component: Component,
};
