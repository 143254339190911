import { Text } from '@workos-inc/component-library';
import { FC } from 'react';
import { Card } from '../../../../components/card';
import { FileField } from '../../../../components/fields';
import { Form } from '../../../../components/form';
import { Img } from '../../../../components/image';
import { useFormContext } from '../../../../shared/form-data-provider';
import { StepData } from '../../../../shared/step-navigation/interfaces';
import { useStepsStore } from '../../../../shared/step-navigation/steps-store-provider';
import { SsoFormMetadata } from '../../interfaces/sso-form-metadata';
import { OneLoginSamlOacFormFields } from './onelogin-saml-oac-steps';

const Component: FC = () => {
  const { handleNextStep } = useStepsStore();
  const { formData, setFormData, formErrors } =
    useFormContext<SsoFormMetadata>();

  return (
    <>
      <Text>Click the “SSO” tab from the left sidebar.</Text>

      <Img
        className="my-8"
        height={1570}
        src="/images/6e21cb8d-5fea-4f4a-9514-aca666d8b52f.png"
        width={2250}
      />

      <Text>
        Click “View Details” under the X.509 Certificate. This will open a
        certificate configuration page.
      </Text>

      <Text>Download the X.509 Certificate and upload it below.</Text>

      <Img
        className="my-8"
        height={1598}
        src="/images/b813c800-4632-4a12-a13a-4384073aec8e.png"
        width={2250}
      />

      <Card>
        <Form
          disabled={!formData.saml_x509_certificates}
          isUpdate={!!formErrors.saml_x509_certificates}
          onSubmit={handleNextStep}
        >
          <FileField
            error={formErrors.saml_x509_certificates}
            id="saml_x509_certs"
            label="X.509 Certificate"
            name="saml_x509_certs"
            onUpload={({ file }) => {
              setFormData({
                saml_x509_certificates: [file.content],
              });
            }}
            value={formData.saml_x509_certificates?.[0]}
          />
        </Form>
      </Card>
    </>
  );
};

export const uploadX509Certificate: StepData<OneLoginSamlOacFormFields> = {
  title: 'Upload X.509 Certificate',
  component: Component,
  formKeys: ['saml_x509_certificates'],
};
