import { Text } from '@workos-inc/component-library';
import { FC } from 'react';
import { Img } from '../../../../components/image';
import { StepData } from '../../../../shared/step-navigation/interfaces';
import { StepContent } from '../../../../shared/step-navigation/step-content';
import { ConnectionAttributeMapping } from '../../attribute-mapping';
import { useSsoStore } from '../../sso-store-provider';

const Component: FC = () => {
  const { connection } = useSsoStore();

  return (
    <StepContent confirmLabel="I've completed the application configuration and SAML attribute mapping.">
      <Text>
        Rippling will now have configuration settings for your preferences
        regarding the application like who should have access and when.
        Configure these settings as needed until you reach the final page
        letting you know the installation has been complete.
      </Text>

      <Img
        className="my-8"
        height={1243}
        src="/images/5798bff1-c50f-44a3-b31a-31b949ef7059.png"
        width={1920}
      />
      <Text>
        Click "Visit the app". The application settings will be presented, here
        we will configure the SAML attribute mapping.
      </Text>

      <Img
        className="my-8"
        height={1243}
        src="/images/6ce1ae24-35ac-4f21-a067-40726a1b8209.png"
        width={1920}
      />

      <Text>
        Select the "Settings Tab" then on the left navigation select "SAML
        Attributes" and select the "SAML Response" tab and use the "edit" button
        to add the following key-value pairs. Then, click "Save".
      </Text>

      <ConnectionAttributeMapping connectionType={connection.type} />
    </StepContent>
  );
};

export const configureRipplingAttributeMapping: StepData = {
  title: 'Configure Attribute Mapping',
  component: Component,
};
