import { Text } from '@workos-inc/component-library';
import { FC } from 'react';
import { Img } from '../../../../components/image';
import { usePortalSession } from '../../../../components/portal-session-provider';
import { StepData } from '../../../../shared/step-navigation/interfaces';
import { StepContent } from '../../../../shared/step-navigation/step-content';

const Component: FC = () => {
  const { appName } = usePortalSession();
  return (
    <StepContent confirmLabel="I’ve created a new SaaS Application">
      <Text>In your Workspace ONE Catalog, click "New".</Text>

      <Img
        className="my-8"
        height={1598}
        src="/images/812c5a3b-f3a1-4944-abd8-6749144cd691.png"
        width={2692}
      />

      <Text>Provide a Name that describes {appName}, then click "Next".</Text>
    </StepContent>
  );
};

export const createANewSaasApplication: StepData = {
  title: 'Create a new SaaS Application',
  component: Component,
};
