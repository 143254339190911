import { Text } from '@workos-inc/component-library';
import { FC } from 'react';
import { Ol } from '../../../../../components/typography';
import { Img } from '../../../../components/image';
import { StepData } from '../../../../shared/step-navigation/interfaces';
import { metadataUrlFormKeys } from '../../constants/metadata-form-keys';
import { MetadataUrlFormFields } from '../../interfaces/metadata-form-fields';
import { MetadataUrlStep } from '../../metadata-step';

const Component: FC = () => (
  <MetadataUrlStep
    idpEntityIdPlaceholder="http://www.okta.com/exkgq2c19CUpt2Brr46"
    idpSsoUrlPlaceholder="http://www.okta.com/exkgq2c19CUpt2Brr46"
    metadataUrlPlaceholder="https://"
  >
    <Text>
      The final step for setting up the WorkOS Okta app requires sharing the
      integration's Identity Provider metadata.
    </Text>

    <Text as="h2" size="large" weight="medium">
      URL Metadata Configuration
    </Text>

    <Ol className="my-4">
      <li>Click on the "Sign On" tab of the app you just added.</li>

      <li>
        Click the "Actions" dropdown for the active certificate and select “View
        IdP Metadata."
      </li>

      <li>
        A separate tab will open. Copy the URL in the address bar and paste it
        below.
      </li>
    </Ol>

    <Img
      className="my-8"
      height={2874}
      src="/images/d32d6db5-6e28-4944-8e94-178d0715dd50.png"
      width={2840}
    />
  </MetadataUrlStep>
);

export const uploadIdentityProviderMetadata: StepData<MetadataUrlFormFields> = {
  title: 'Upload Identity Provider Metadata',
  formKeys: metadataUrlFormKeys,
  component: Component,
};
