import { Text } from '@workos-inc/component-library';
import { FC } from 'react';
import { Img } from '../../../../components/image';
import { CopyInput } from '../../../../components/inputs';
import { StepData } from '../../../../shared/step-navigation/interfaces';
import { StepContent } from '../../../../shared/step-navigation/step-content';
import { useSsoStore } from '../../sso-store-provider';

const Component: FC = () => {
  const { connection } = useSsoStore();

  return (
    <StepContent confirmLabel="I've entered the External Key and added the WorkOS App integration.">
      <Text>
        In your Okta Admin Dashboard, click on "Applications" in the sidebar.
        Then, select "Browse App Catalog", search for "WorkOS", and click on the
        application.
      </Text>

      <Img
        className="my-8"
        height={1750}
        src="/images/b1d045d0-7529-4b94-9a21-9e0df7c88274.png"
        width={2860}
      />

      <Text>Click "Add Integration".</Text>

      <Img
        className="my-8"
        height={1750}
        src="/images/a9302954-4078-4e6c-86be-594957d5cdb4.png"
        width={2860}
      />

      <Text>
        Enter a label for the WorkOS app integration. Then, copy the "External
        Key" below into the matching field in the Okta WorkOS app configuration
        page. Finally, click "Done".
      </Text>

      <CopyInput
        id="connection-external-key"
        label="Copy WorkOS External Key"
        value={connection.externalKey}
      />

      <Img
        className="my-8"
        height={1750}
        src="/images/a5dfecaa-60b5-445c-91fc-3120f60c591c.png"
        width={2860}
      />
    </StepContent>
  );
};

export const createWorkosOinAppIntegration: StepData = {
  title: 'Add WorkOS App Integration',
  component: Component,
};
