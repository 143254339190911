import { Text } from '@workos-inc/component-library';
import { FC } from 'react';
import { Img } from '../../../../components/image';
import { StepData } from '../../../../shared/step-navigation/interfaces';
import { StepContent } from '../../../../shared/step-navigation/step-content';

const Component: FC = () => (
  <StepContent confirmLabel="I've configured the SAML Credentials.">
    <Text>
      Navigate to the "Credentials" page, and select the "Configure Credentials"
      button.
    </Text>

    <Img
      className="my-8"
      height={892}
      src="/images/17f48eed-3f1e-4ecd-bae9-9dae752bcbd2.png"
      width={2266}
    />

    <Text>
      On the "Digital Signature Settings" page, select a signing certificate and
      the RSA SHA256 signing algorithm, then click "Done".
    </Text>

    <Img
      className="my-8"
      height={934}
      src="/images/ab93026e-6e26-44dc-9b38-b9ff210e545d.png"
      width={2266}
    />
  </StepContent>
);

export const configureCredentials: StepData = {
  title: 'Configure Credentials',
  component: Component,
};
