import { Step, StepData } from '../interfaces';
import { getStepIdentifier } from './get-step-identifier';

export const generateSteps =
  (ConfirmationStep: React.ElementType) =>
  <TFormData>(stepsData: StepData<TFormData>[]): Step<TFormData>[] => {
    const stepsWithConfirmationData = stepsData.map((stepData) => ({
      ...stepData,
      confirmationStep: false,
    }));

    return [
      ...stepsWithConfirmationData,
      {
        title: 'Confirmation',
        component: ConfirmationStep,
        confirmationStep: true,
      },
    ].map((step, index) => ({
      ...step,
      stepIdentifier: getStepIdentifier(index + 1, step.title),
    }));
  };
