import { Text } from '@workos-inc/component-library';
import { FC } from 'react';
import { Img } from '../../../../components/image';
import { CopyInput } from '../../../../components/inputs';
import { StepData } from '../../../../shared/step-navigation/interfaces';
import { StepContent } from '../../../../shared/step-navigation/step-content';
import { useSsoStore } from '../../sso-store-provider';

const Component: FC = () => {
  const { connection } = useSsoStore();

  return (
    <StepContent
      confirmLabel="I’ve configured the Assertion Consumer Service (ACS) URL and
    Entity ID."
    >
      <Text>
        Copy the Entity ID into the "IdP Entity ID" and "SP Entity ID" fields.
      </Text>

      <CopyInput
        id="saml-entity-id"
        label="Copy this Entity ID"
        value={connection.saml_entity_id}
      />

      <Text>Copy the ACS URL into the "ACS URL" field.</Text>

      <CopyInput
        id="saml-acs-url"
        label="Copy this ACS URL"
        value={connection.saml_acs_url}
      />

      <Img
        className="my-8"
        height={716}
        src="/images/893862e8-e577-4407-b76f-494e4ab8bc64.png"
        width={1167}
      />

      <Text>IMPORTANT: Be sure to check the “Sign Assertion” box.</Text>

      <Img
        className="my-8"
        height={1226}
        src="/images/e4cca1f9-3f24-45d6-80f7-3ef318eb4ba2.png"
        width={2120}
      />
    </StepContent>
  );
};

export const submitAcsUrlAndEntityId: StepData = {
  title: 'Submit ACS URL and Entity ID',
  component: Component,
};
