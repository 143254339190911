import { Text } from '@workos-inc/component-library';
import { FC } from 'react';
import { Img } from '../../../../components/image';
import { StepData } from '../../../../shared/step-navigation/interfaces';
import { metadataXmlFormKeys } from '../../constants/metadata-form-keys';
import { MetadataXmlFormFields } from '../../interfaces/metadata-form-fields';
import { MetadataXmlStep } from '../../metadata-step';

const Component: FC = () => (
  <MetadataXmlStep
    idpEntityIdPlaceholder="https://auth.workos.com/AbC123..."
    idpSsoUrlPlaceholder="https://mypingfederate.com/idp/SSO.saml2"
  >
    <Text>
      On the SP Connection list, find your WorkOS SAML 2.0 connection. Click on
      the "Select Action" menu and then select "Export Metadata" to download the
      connection metadata.
    </Text>

    <Img
      className="my-8"
      height={934}
      src="/images/d7e53b82-177b-42a7-b2e6-55133a1afec7.png"
      width={2266}
    />
  </MetadataXmlStep>
);

export const uploadIdentityProviderMetadata: StepData<MetadataXmlFormFields> = {
  title: 'Upload Identity Provider Metadata',
  component: Component,
  formKeys: metadataXmlFormKeys,
};
