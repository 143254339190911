import { FC } from 'react';
import { Step } from '../../../../shared/step-navigation';
import { useSsoStepRoutes } from '../../hooks/use-sso-step-routes';
import { SsoStepNavigation } from '../../sso-step-navigation';
import { generateSsoSteps } from '../../utils/generate-sso-steps';
import { setupOktaApplication } from './1-setup-okta-application';

interface OktaSamlOinStepsProps {}

const steps: Step[] = generateSsoSteps([setupOktaApplication]);

export const OktaSamlOinSteps: FC<Readonly<OktaSamlOinStepsProps>> = () => {
  const stepRoutes = useSsoStepRoutes(steps);

  return <SsoStepNavigation steps={steps}>{stepRoutes}</SsoStepNavigation>;
};
