import { Text } from '@workos-inc/component-library';
import { FC } from 'react';
import { NextStep } from '../../../../components/next-step';
import { usePortalSession } from '../../../../components/portal-session-provider';
import { StepData } from '../../../../shared/step-navigation/interfaces';
import { useStepsStore } from '../../../../shared/step-navigation/steps-store-provider';
import { useDsyncStore } from '../../dsync-store-provider';
import { MapAttributes } from '../../map-attributes';

const Component: FC = () => {
  const { appName } = usePortalSession();
  const { directory, directoryCustomAttributes } = useDsyncStore();
  const { handleNextStep } = useStepsStore();

  return (
    <>
      <Text>
        Map the required fields below to the corresponding columns you added to
        the custom reports. You can use the attribute names listed in the
        "Column Heading Override XML Alias" from the Workday custom report.
      </Text>

      <MapAttributes
        appName={appName}
        directory={directory}
        directoryCustomAttributes={directoryCustomAttributes || []}
        onFinishMapping={handleNextStep}
      >
        {(buttonProps) => (
          <NextStep
            label="I’ve finished configuring custom report mappings."
            {...buttonProps}
          />
        )}
      </MapAttributes>
    </>
  );
};

export const mapCustomReportFields: StepData = {
  title: 'Map custom report fields',
  component: Component,
};
