import { FC } from 'react';
import { Step } from '../../../../shared/step-navigation';
import { useSsoStepRoutes } from '../../hooks/use-sso-step-routes';
import { MetadataXmlFormFields } from '../../interfaces/metadata-form-fields';
import { SsoStepNavigation } from '../../sso-step-navigation';
import { generateSsoSteps } from '../../utils/generate-sso-steps';
import { createASamlApplication } from './1-create-a-saml-application';
import { basicSamlConfiguration } from './2-basic-saml-configuration';
import { advancedSamlConfiguration } from './3-advanced-saml-configuration';
import { configureParameters } from './4-configure-parameters';
import { uploadOneLoginMetadataFile } from './5-upload-onelogin-metadata-file';

interface OneLoginSamlStepsProps {}

const steps: Step<MetadataXmlFormFields>[] = generateSsoSteps([
  createASamlApplication,
  basicSamlConfiguration,
  advancedSamlConfiguration,
  configureParameters,
  uploadOneLoginMetadataFile,
]);

export const OneLoginSamlSteps: FC<Readonly<OneLoginSamlStepsProps>> = () => {
  const stepRoutes = useSsoStepRoutes(steps);

  return <SsoStepNavigation steps={steps}>{stepRoutes}</SsoStepNavigation>;
};
