import { Text } from '@workos-inc/component-library';
import { FC } from 'react';
import { StepData } from '../../../../shared/step-navigation/interfaces';
import { metadataUrlFormKeys } from '../../constants/metadata-form-keys';
import { MetadataUrlFormFields } from '../../interfaces/metadata-form-fields';
import { MetadataUrlStep } from '../../metadata-step';

const Component: FC = () => (
  <MetadataUrlStep
    idpEntityIdPlaceholder="https://myclasslinkserver.com/abc123..."
    idpSsoUrlPlaceholder="https://myclasslinkserver.com/..."
    metadataUrlPlaceholder="https://"
  >
    <Text>
      To connect with your ClassLink instance, please upload the ClassLink SAML
      application metadata.
    </Text>

    <Text as="h2" size="large" weight="medium">
      URL Metadata Configuration
    </Text>

    <Text>
      Upload the IdP metadata URL from your SAML settings. Alternatively, you
      can manually configure the connection by providing the SSO URL, IdP Issuer
      and X.509 Certificate.
    </Text>
  </MetadataUrlStep>
);

export const uploadYourIdentityProviderMetadata: StepData<MetadataUrlFormFields> =
  {
    title: 'Upload your Identity Provider Metadata',
    component: Component,
    formKeys: metadataUrlFormKeys,
  };
