import { FC } from 'react';
import { Step } from '../../../../shared/step-navigation';
import { useSsoStepRoutes } from '../../hooks/use-sso-step-routes';
import { MetadataUrlFormFields } from '../../interfaces/metadata-form-fields';
import { SsoStepNavigation } from '../../sso-step-navigation';
import { generateSsoSteps } from '../../utils/generate-sso-steps';
import { createWorkosOinAppIntegration } from './1-add-workos-app-integration';
import { uploadIdentityProviderMetadata } from './2-upload-identity-provider-metadata';

interface OktaSamlOinWorkosStepsProps {}

const steps: Step<MetadataUrlFormFields>[] = generateSsoSteps([
  createWorkosOinAppIntegration,
  uploadIdentityProviderMetadata,
]);

export const OktaSamlOinWorkosSteps: FC<
  Readonly<OktaSamlOinWorkosStepsProps>
> = () => {
  const stepRoutes = useSsoStepRoutes(steps);

  return <SsoStepNavigation steps={steps}>{stepRoutes}</SsoStepNavigation>;
};
