import { FC } from 'react';
import { Step } from '../../../../shared/step-navigation';
import { useSsoStepRoutes } from '../../hooks/use-sso-step-routes';
import { MetadataUrlFormFields } from '../../interfaces/metadata-form-fields';
import { SsoStepNavigation } from '../../sso-step-navigation';
import { generateSsoSteps } from '../../utils/generate-sso-steps';
import { provideIdpInformation } from './1-provide-idp-information';
import { createAGenericSamlApplication } from './2-create-a-generic-saml-application';
import { provideTheSpEntityId } from './3-provide-the-sp-entity-id';
import { uploadYourIdentityProviderMetadata } from './4-upload-your-identity-provider-metadata';
import { configureYourSamlClaims } from './5-configure-your-saml-claims';

interface GenericSamlStepsProps {}

const steps: Step<MetadataUrlFormFields>[] = generateSsoSteps([
  provideIdpInformation,
  createAGenericSamlApplication,
  provideTheSpEntityId,
  uploadYourIdentityProviderMetadata,
  configureYourSamlClaims,
]);

export const GenericSamlSteps: FC<Readonly<GenericSamlStepsProps>> = () => {
  const stepRoutes = useSsoStepRoutes(steps);

  return <SsoStepNavigation steps={steps}>{stepRoutes}</SsoStepNavigation>;
};
