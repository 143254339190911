import {
  createContext,
  PropsWithChildren,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { AnyObject, RecordStateDispatcher } from '../../hooks/use-record-state';
import {
  FormDataErrors,
  useFormContext,
} from '../form-data-provider/form-data-provider';
import { StepWithPath } from './interfaces';
import { StepCompleteEventBase } from './interfaces/step-complete-event-base';
import { extractCurrentStepFromPathname } from './utils';

type StepsStoreContext<TFormData extends AnyObject> = {
  currentStepNumber: number;
  currentStepData?: StepWithPath<TFormData>;
  stepByFormKey: Record<keyof TFormData, number>;
  handleInputChange: (event: {
    target: { name: string; value: string };
  }) => void;
  handleNextStep: () => Promise<void>;
  handleConfirmationStep: () => Promise<void>;
  isUpdating: boolean;
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const StepsContext = createContext<StepsStoreContext<any> | null>(null);

export const useStepsStore = <TFormData extends AnyObject>() => {
  const context = useContext<StepsStoreContext<TFormData> | null>(StepsContext);

  if (!context) {
    throw new Error('useStepsStore can only be used within StepsContext');
  }

  return context;
};

export interface StepsStoreProviderProps<TFormData extends AnyObject> {
  clientSideFormValidator?: (
    formData: TFormData,
  ) => FormDataErrors<TFormData> | undefined;
  onUpdateWithChanges: (
    formData: TFormData,
    setFormError: RecordStateDispatcher<FormDataErrors<TFormData>>,
  ) => Promise<void>;
  steps: StepWithPath<TFormData>[];
  stepsCompleteEventTacker?: (arg: StepCompleteEventBase<TFormData>) => void;
  stepsUrlBase: string;
}

export const StepsStoreProvider = <TFormData extends AnyObject>({
  children,
  clientSideFormValidator,
  onUpdateWithChanges,
  steps,
  stepsCompleteEventTacker,
  stepsUrlBase,
}: PropsWithChildren<StepsStoreProviderProps<TFormData>>) => {
  const navigate = useNavigate();
  const { pathname } = useLocation();
  const [stepStartDate, setStepStartDate] = useState<Date>(new Date());
  const stepByFormKey = useMemo(
    () =>
      steps.reduce((_stepByFormKey, curr, i) => {
        curr.formKeys?.forEach((formKey) => {
          _stepByFormKey[formKey] = i + 1;
        });
        return _stepByFormKey;
      }, {} as Record<keyof TFormData, number>),
    [steps],
  );

  const currentStepNumber = useMemo(() => {
    try {
      return extractCurrentStepFromPathname(stepsUrlBase, pathname);
    } catch (error) {
      return 0; // returning 0 here triggers the useEffect to redirect to /{intent}/configure/1
    }
  }, [pathname, stepsUrlBase]);

  const { formData, setFormData, setFormErrors } = useFormContext<TFormData>();
  const [isUpdating, setIsUpdating] = useState<boolean>(false);

  useEffect(() => {
    if (currentStepNumber > steps.length || currentStepNumber < 1) {
      navigate(`${stepsUrlBase}/1`);
    }
  }, [currentStepNumber, steps, navigate, stepsUrlBase]);

  useEffect(() => {
    setStepStartDate(new Date());
  }, [currentStepNumber]);

  const handleInputChange = useCallback(
    (event: { target: { name: string; value: string } }) => {
      const payload = { [event.target.name]: event.target.value };

      setFormData({ ...formData, ...payload });
    },
    [formData, setFormData],
  );

  const handleConfirmationStep = useCallback(async () => {
    const nextStepNumber = currentStepNumber + 1;
    const nextStep = steps[nextStepNumber - 1];
    if (!nextStep) {
      setIsUpdating(true);
      await onUpdateWithChanges(formData, setFormErrors);
      setIsUpdating(false);
    }
  }, [steps, formData, setFormErrors, currentStepNumber, onUpdateWithChanges]);

  const handleNextStep = useCallback(async () => {
    const clientSideFormErrors = clientSideFormValidator?.(formData);

    if (clientSideFormErrors) {
      const formErrorKeys = Object.keys(clientSideFormErrors) as Extract<
        keyof TFormData,
        string
      >[];

      const hasFormErrorOnCurrentStep = formErrorKeys.some(
        (key) => currentStepNumber === stepByFormKey[key],
      );

      setFormErrors(clientSideFormErrors);

      if (hasFormErrorOnCurrentStep) {
        return;
      }
    }

    setIsUpdating(true);
    const nextStepNumber = currentStepNumber + 1;
    const nextStep = steps[nextStepNumber - 1];
    const nextStepPath = `${stepsUrlBase}/${nextStepNumber}`;

    if (nextStep?.confirmationStep && Object.keys(formData).length > 0) {
      await onUpdateWithChanges(formData, setFormErrors);
    }

    setIsUpdating(false);
    const currentStepIndex = currentStepNumber - 1;
    const currentStepData = steps[currentStepIndex];
    if (currentStepData) {
      void stepsCompleteEventTacker?.({
        stepData: currentStepData,
        stepNumber: currentStepNumber,
        stepStartDate,
      });
    }

    navigate(nextStepPath);
  }, [
    stepStartDate,
    stepsCompleteEventTacker,
    clientSideFormValidator,
    currentStepNumber,
    formData,
    navigate,
    onUpdateWithChanges,
    setFormErrors,
    stepByFormKey,
    steps,
    stepsUrlBase,
  ]);

  const context = useMemo(
    () => ({
      currentStepNumber,
      stepByFormKey,
      handleConfirmationStep,
      handleInputChange,
      handleNextStep,
      isUpdating,
    }),
    [
      currentStepNumber,
      stepByFormKey,
      handleConfirmationStep,
      handleInputChange,
      handleNextStep,
      isUpdating,
    ],
  );

  return (
    <StepsContext.Provider value={context}>{children}</StepsContext.Provider>
  );
};
