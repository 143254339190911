import { Step } from '../../../shared/step-navigation/interfaces';
import { useDsyncStore } from '../dsync-store-provider';

export const useEnabledDsyncSteps = <DsyncFormData,>(
  steps: Step<DsyncFormData>[],
) => {
  const { directoryCustomAttributes } = useDsyncStore();
  const filteredSteps = steps.filter(
    (step) => !step.customAttributesStep || !!directoryCustomAttributes.length,
  );
  return filteredSteps;
};
