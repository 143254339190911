import { useFeatureToggle } from '@flopflip/react-broadcast';
import { unreachable } from '@workos-inc/standard';
import { FC, useCallback } from 'react';
import { Navigate } from 'react-router-dom';
import { ConnectionType } from '../../../../graphql/generated';
import { PageTitle } from '../../../components/with-page-title';
import { AdfsSamlSteps } from '../providers/adfs-saml';
import { AdpOidcSteps } from '../providers/adp-oidc';
import { Auth0SamlSteps } from '../providers/auth0-saml';
import { AzureSamlSteps } from '../providers/azure-saml';
import { CasSamlSteps } from '../providers/cas-saml';
import { ClassLinkSamlSteps } from '../providers/classlink-saml';
import { CloudflareSamlSteps } from '../providers/cloudflare-saml';
import { CyberarkSamlSteps } from '../providers/cyberark-saml';
import { DuoSamlSteps } from '../providers/duo-saml';
import { GenericOidcSteps } from '../providers/generic-oidc';
import { GenericSamlSteps } from '../providers/generic-saml';
import { GoogleSamlSteps } from '../providers/google-saml';
import { JumpCloudSamlSteps } from '../providers/jump-cloud-saml';
import { KeycloakSamlSteps } from '../providers/keycloak-saml';
import { LastPassSamlSteps } from '../providers/last-pass-saml';
import { MiniOrangeSamlSteps } from '../providers/mini-orange-saml';
import { NetIqSamlSteps } from '../providers/net-iq-saml';
import { OktaSamlSteps } from '../providers/okta-saml';
import { OktaSamlOinSteps } from '../providers/okta-saml-oin';
import { OktaSamlOinWorkosSteps } from '../providers/okta-saml-oin-workos';
import { OneLoginSamlSteps } from '../providers/onelogin-saml';
import { OneLoginSamlOacSteps } from '../providers/onelogin-saml-oac';
import { OracleSamlSteps } from '../providers/oracle-saml';
import { PingFederateSamlSteps } from '../providers/pingfederate-saml';
import { PingOneSamlSteps } from '../providers/pingone-saml';
import { RipplingSamlSteps } from '../providers/rippling-saml';
import { SalesforceSamlSteps } from '../providers/salesforce-saml/';
import { ShibbolethGenericSamlSteps } from '../providers/shibboleth-generic-saml';
import { ShibbolethUnsolicitedSamlSteps } from '../providers/shibboleth-unsolicited-saml';
import { SimpleSamlPhpSamlSteps } from '../providers/simple-saml-php-saml';
import { VMwareSamlSteps } from '../providers/vmware-saml';
import { useSsoStore } from '../sso-store-provider';
import { getConnectionName } from '../utils/get-connection-name';
import { getSupportedConnectionType } from '../utils/get-supported-connection-type';

interface SsoStepsProps {}

export const SsoSteps: FC<Readonly<SsoStepsProps>> = () => {
  const { connection } = useSsoStore();

  const isOktaOinEnabled = useFeatureToggle('oktaOinAdminPortalSso');
  const isWorkosOktaOinEnabled = useFeatureToggle(
    'workosOktaOinAdminPortalSso',
  );
  const isOneLoginOacEnabled = useFeatureToggle('oneLoginOacAdminPortalSso');

  const connectionType = getSupportedConnectionType(connection);

  const steps = useCallback(() => {
    if (!connectionType) {
      return null;
    }

    switch (connectionType) {
      case ConnectionType.Adfssaml:
        return <AdfsSamlSteps />;
      case ConnectionType.AdpOidc:
        return <AdpOidcSteps />;
      case ConnectionType.Auth0Saml:
        return <Auth0SamlSteps />;
      case ConnectionType.AzureSaml:
        return <AzureSamlSteps />;
      case ConnectionType.CasSaml:
        return <CasSamlSteps />;
      case ConnectionType.ClassLinkSaml:
        return <ClassLinkSamlSteps />;
      case ConnectionType.CloudflareSaml:
        return <CloudflareSamlSteps />;
      case ConnectionType.CyberArkSaml:
        return <CyberarkSamlSteps />;
      case ConnectionType.DuoSaml:
        return <DuoSamlSteps />;
      case ConnectionType.GenericOidc:
        return <GenericOidcSteps />;
      case ConnectionType.GenericSaml:
        return <GenericSamlSteps />;
      case ConnectionType.GoogleSaml:
        return <GoogleSamlSteps />;
      case ConnectionType.JumpCloudSaml:
        return <JumpCloudSamlSteps />;
      case ConnectionType.KeycloakSaml:
        return <KeycloakSamlSteps />;
      case ConnectionType.LastPassSaml:
        return <LastPassSamlSteps />;
      case ConnectionType.MiniOrangeSaml:
        return <MiniOrangeSamlSteps />;
      case ConnectionType.NetIqSaml:
        return <NetIqSamlSteps />;
      case ConnectionType.OktaSaml:
        if (isWorkosOktaOinEnabled) {
          return <OktaSamlOinWorkosSteps />;
        }

        if (isOktaOinEnabled) {
          return <OktaSamlOinSteps />;
        }

        return <OktaSamlSteps />;
      case ConnectionType.OneLoginSaml:
        if (isOneLoginOacEnabled) {
          return <OneLoginSamlOacSteps />;
        }

        return <OneLoginSamlSteps />;
      case ConnectionType.OracleSaml:
        return <OracleSamlSteps />;
      case ConnectionType.PingFederateSaml:
        return <PingFederateSamlSteps />;
      case ConnectionType.PingOneSaml:
        return <PingOneSamlSteps />;
      case ConnectionType.RipplingSaml:
        return <RipplingSamlSteps />;
      case ConnectionType.SalesforceSaml:
        return <SalesforceSamlSteps />;
      case ConnectionType.ShibbolethGenericSaml:
        return <ShibbolethGenericSamlSteps />;
      case ConnectionType.ShibbolethSaml:
        return <ShibbolethUnsolicitedSamlSteps />;
      case ConnectionType.SimpleSamlPhpSaml:
        return <SimpleSamlPhpSamlSteps />;
      case ConnectionType.VMwareSaml:
        return <VMwareSamlSteps />;
      default:
        return unreachable(connectionType);
    }
  }, [
    connectionType,
    isOktaOinEnabled,
    isOneLoginOacEnabled,
    isWorkosOktaOinEnabled,
  ]);

  if (!connectionType) {
    return <Navigate to="/unsupported-provider" />;
  }

  const connectionProviderName = getConnectionName(connectionType);

  return (
    <PageTitle title={`Configure SSO with ${connectionProviderName}`}>
      {steps()}
    </PageTitle>
  );
};
