import { Text } from '@workos-inc/component-library';
import { FC } from 'react';
import { Img } from '../../../../components/image';
import { usePortalSession } from '../../../../components/portal-session-provider';
import { StepData } from '../../../../shared/step-navigation/interfaces';
import { StepContent } from '../../../../shared/step-navigation/step-content';
import { useSsoStore } from '../../sso-store-provider';

const Component: FC = () => {
  const { connection } = useSsoStore();
  const { appName } = usePortalSession();

  return (
    <StepContent
      confirmLabel={
        'I\'ve finished creating the SAML Integration and clicked "Continue to Next Step".'
      }
    >
      <Text>
        On the "Applications" tab, select "My Applications". Click on "Add
        Application" and select "New SAML Application"
      </Text>

      <Img
        height={1732}
        src="/images/09723c34-9371-4916-8c4e-a90d60b6d09d.png"
        width={2270}
      />

      <Text>
        Give it a descriptive "Application Name" to easily identify it later,
        such as {appName}-{connection.name}. Then fill out the "Application
        Description" and select a "Category".
      </Text>

      <Img
        height={1732}
        src="/images/55f075db-7930-41c8-8f27-e6f8e8a44899.png"
        width={2270}
      />

      <Text>Click "Continue to Next Step".</Text>
    </StepContent>
  );
};

export const createSamlIntegration: StepData = {
  title: 'Create SAML Integration',
  component: Component,
};
