import { Text } from '@workos-inc/component-library';
import { FC } from 'react';
import { Img } from '../../../../components/image';
import { StepData } from '../../../../shared/step-navigation/interfaces';
import { StepContent } from '../../../../shared/step-navigation/step-content';

const Component: FC = () => (
  <StepContent
    confirmLabel={'I’ve completed adding users and clicked “Save & continue”.'}
  >
    <Text>
      On the "Users, groups {'&'} roles" page, click on "Assign users, groups{' '}
      {'&'} roles".
    </Text>

    <Img
      height={1790}
      src="/images/cec59ee3-85f1-45cd-8ed5-03dfef194de9.png"
      width={2430}
    />

    <Text>
      Search and select any users or groups that you would like to provision to
      this SAML app. Then, click "Assign".
    </Text>

    <Img
      height={1790}
      src="/images/fc3fd859-1092-45a8-922f-38b4619863d8.png"
      width={2430}
    />

    <Text>Click on "Save {'&'} continue".</Text>

    <Img
      height={1426}
      src="/images/4b775f48-9045-4122-8c9c-4770370edd4b.png"
      width={2298}
    />
  </StepContent>
);
export const addUsersToSamlApp: StepData = {
  title: 'Add Users to SAML App',
  component: Component,
};
