import { InputGroup, Text } from '@workos-inc/component-library';
import { FC } from 'react';
import { Card } from '../../../../components/card';
import { Form } from '../../../../components/form';
import { useFormContext } from '../../../../shared/form-data-provider';
import { StepData } from '../../../../shared/step-navigation/interfaces';
import { useStepsStore } from '../../../../shared/step-navigation/steps-store-provider';
import { SsoFormMetadata } from '../../interfaces/sso-form-metadata';
import { GenericOidcFormFields } from './generic-oidc-steps';

const Component: FC = () => {
  const { isUpdating, handleNextStep, handleInputChange } = useStepsStore();
  const { formData, formErrors } = useFormContext<SsoFormMetadata>();

  return (
    <>
      <Text>
        Your Identity Provider's Discovery Endpoint contains important
        configuration information.
      </Text>

      <Card>
        <Form
          isInline
          disabled={!formData.oidc_discovery_endpoint}
          isLoading={isUpdating}
          isUpdate={!!formErrors?.oidc_discovery_endpoint}
          onSubmit={handleNextStep}
        >
          <InputGroup
            autoFocus
            error={
              formErrors?.oidc_discovery_endpoint?.message
              // || validationErrors?.oidc_discovery_endpoint?.message
            }
            id="oidc_discovery_endpoint"
            label="Discovery Endpoint"
            name="oidc_discovery_endpoint"
            onChange={handleInputChange}
            placeholder="https://your-domain.com/.well-known/openid-configuration"
            value={formData.oidc_discovery_endpoint ?? ''}
          />
        </Form>
      </Card>
    </>
  );
};

export const provideYourDiscoveryEndpoint: StepData<GenericOidcFormFields> = {
  title: 'Provide your Discovery Endpoint',
  component: Component,
  formKeys: ['oidc_discovery_endpoint'],
};
