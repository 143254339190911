import { Text } from '@workos-inc/component-library';
import { FC } from 'react';
import { Img } from '../../../../components/image';
import { StepData } from '../../../../shared/step-navigation/interfaces';
import { StepContent } from '../../../../shared/step-navigation/step-content';

const Component: FC = () => (
  <StepContent confirmLabel="I’ve activated the SP Connection in PingFederate.">
    <Text>
      On the Activation {'&'} Summary page, turn on provisioning with the toggle
      at the top, and then select "Save".
    </Text>

    <Img
      className="my-8"
      height={2778}
      src="/images/eb68ab8a-8ae6-47a5-bb6c-2361e84502f1.png"
      width={2432}
    />

    <Text>
      You’ll now see your SCIM application listed in the SP Connections page.
    </Text>

    <Img
      className="my-8"
      height={954}
      src="/images/83bbf028-4ceb-4e0a-9fd7-6956a0d10f7e.png"
      width={2432}
    />

    <Text>
      The provisioning will automatically begin when the connection is activated
      through outbound requests from Ping Federate. It may take a few minutes
      for this process to start.
    </Text>
  </StepContent>
);

export const activateTheSpConnectionInPingFederate: StepData = {
  title: 'Activate the SP Connection in PingFederate',
  component: Component,
};
