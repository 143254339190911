import { ConnectionType } from '../../../../graphql/generated';
import { AnyObject } from '../../../hooks/use-record-state';
import { StepCompleteEventBase } from '../../../shared/step-navigation/interfaces/step-complete-event-base';
import { EventName } from '../../../utils/constants';
import { graphql } from '../../../utils/graphql';

export interface SsoStepCompleteEvent<TFormData extends AnyObject>
  extends StepCompleteEventBase<TFormData> {
  connectionType: ConnectionType;
}

export const trackSsoStepCompleteEvent = async <TFormData extends AnyObject>({
  stepData: currentStepData,
  stepNumber: currentStepNumber,
  connectionType,
  stepStartDate,
}: SsoStepCompleteEvent<TFormData>) => {
  await graphql().TrackEvent({
    event_name: EventName.SSOStepCompleted,
    attributes: {
      step_name: currentStepData?.title,
      step_number: currentStepNumber,
      connection_type: connectionType,
      duration: new Date().getTime() - stepStartDate.getTime(),
    },
  });
};
