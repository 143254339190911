import { Text } from '@workos-inc/component-library';
import { FC } from 'react';
import { Img } from '../../../../components/image';
import { StepData } from '../../../../shared/step-navigation/interfaces';
import { StepContent } from '../../../../shared/step-navigation/step-content';

const Component: FC = () => (
  <StepContent confirmLabel="I've created the Integration System User.">
    <Text>
      First, create a new Integration System User within Workday. The
      Integration System User will be used to access Custom Reports.
    </Text>

    <Img
      className="my-8"
      height={1226}
      src="/images/c66ff540-7190-4709-8a39-c7803e3c86fd.png"
      width={3664}
    />
  </StepContent>
);

export const createAnIntegrationSystemUser: StepData = {
  title: 'Create an Integration System User',
  component: Component,
};
