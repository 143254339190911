import { Text } from '@workos-inc/component-library';
import { FC } from 'react';
import { Img } from '../../../../components/image';
import { StepData } from '../../../../shared/step-navigation/interfaces';
import { StepContent } from '../../../../shared/step-navigation/step-content';

const Component: FC = () => (
  <StepContent confirmLabel="I've configured the application and confirmed that I am the Application Admin.">
    <Img
      className="my-8"
      height={1306}
      src="/images/76540f8d-0cfa-49b9-8f52-984ac495bb6e.png"
      width={1920}
    />

    <Text>
      Log in to Rippling as an Adminstrator and select "IT Management" then
      "Custom App" from the left-side navigation bar.
    </Text>

    <Img
      className="my-8"
      height={1252}
      src="/images/cbd2bf38-341d-4eef-912b-fd8409c7e34a.png"
      width={1920}
    />

    <Text>
      Select "Create New App" to begin creating a new SAML application.
    </Text>

    <Img
      className="my-8"
      height={1277}
      src="/images/9ba81da7-adc9-4682-b110-1ee120e50205.png"
      width={1920}
    />

    <Text>
      Give the app a descriptive name. You can select a category if relevant,
      but this field is not required. You will however, need to upload a logo
      file. Make sure to check the box for "Supports SAML", then click "Create
      App".
    </Text>

    <Img
      className="my-8"
      height={1277}
      src="/images/bee6d56d-7063-466a-bf0a-4e9930bf2469.png"
      width={1920}
    />
    <Text>
      Select the option confirming that you are the Application Admin. Rippling
      will display a new page with information we will use in the next step.
    </Text>
  </StepContent>
);

export const createRipplingSamlApplication: StepData = {
  title: 'Create Rippling SAML Application',
  component: Component,
};
