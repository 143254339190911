import { Text } from '@workos-inc/component-library';
import { FC } from 'react';
import { Img } from '../../../../components/image';
import { usePortalSession } from '../../../../components/portal-session-provider';
import { StepData } from '../../../../shared/step-navigation/interfaces';
import { StepContent } from '../../../../shared/step-navigation/step-content';
import { HibobFormData } from './hibob-steps';

const Component: FC = () => {
  const { appName } = usePortalSession();

  return (
    <StepContent confirmLabel="I've created a Hibob service user with Name and Display Name.">
      <Text>
        Log in to your Hibob instance as an admin user and navigate to settings,
        then select the "Integrations" tab.
      </Text>

      <Img
        className="my-8"
        height={497}
        src="/images/db6655f5-0d41-4200-8480-854e92d6ccf0.png"
        width={1050}
      />

      <Text>
        There will be a tile called Service Users, click on that then, press on
        the New Service user Button.
      </Text>

      <Text>
        You will be prompted to enter a Name and Display Name for the service
        user. Give the Service User a descriptive name and display name such as{' '}
        <strong>{appName} SCIM User</strong> and select "Done".
      </Text>
      <Img
        className="my-8"
        height={668}
        src="/images/898e5fa5-264b-4990-8224-e291ab253dde.png"
        width={1051}
      />
    </StepContent>
  );
};

export const createAServiceUserInHibob: StepData<HibobFormData> = {
  title: 'Create a Service User in Hibob',
  component: Component,
};
