import { Text } from '@workos-inc/component-library';
import { FC } from 'react';
import { ExternalLink } from '../../../../components/link';
import { StepData } from '../../../../shared/step-navigation/interfaces';
import { metadataXmlFormKeys } from '../../constants/metadata-form-keys';
import { MetadataXmlFormFields } from '../../interfaces/metadata-form-fields';
import { MetadataXmlStep } from '../../metadata-step';

const Component: FC = () => (
  <MetadataXmlStep
    idpEntityIdPlaceholder="https://mysamlserver.com/abc123..."
    idpSsoUrlPlaceholder="https://mysamlserver.com/..."
  >
    <Text>
      Download the IdP metadata from the Shibboleth instance. Refer to the{' '}
      <ExternalLink
        newTab
        appearance="highlight"
        href="https://shibboleth.atlassian.net/wiki/spaces/CONCEPT/pages/928645275/MetadataForIdP"
      >
        Shibboleth documentation
      </ExternalLink>{' '}
      for more information on this metadata file. Upload the file below.
    </Text>
  </MetadataXmlStep>
);

export const uploadIdentityProviderMetadata: StepData<MetadataXmlFormFields> = {
  title: 'Upload Identity Provider Metadata',
  component: Component,
  formKeys: metadataXmlFormKeys,
};
