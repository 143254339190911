import { FC } from 'react';
import { Step } from '../../../../shared/step-navigation';
import { useSsoStepRoutes } from '../../hooks/use-sso-step-routes';
import { SsoStepNavigation } from '../../sso-step-navigation';
import { generateSsoSteps } from '../../utils/generate-sso-steps';
import { connectCloudflareToIdentityProvider } from './1-connect-cloudflare-to-identity-provider';
import { addAnSsoApplicationInCloudflare } from './2-add-an-sso-application-in-cloudflare';
import { configureAttributeStatements } from './3-configure-attribute-statements';
import { formatTheX509Certificate } from './4-format-the-x509-certificate';
import { uploadIdentityProviderMetadata } from './5-upload-identity-provider-metadata';

interface CloudflareSamlStepsProps {}

export type CloudFlareSamlFormFields = {
  saml_x509_certificates: string[];
  saml_idp_url: string;
  saml_idp_entity_id: string;
};

const steps: Step<CloudFlareSamlFormFields>[] = generateSsoSteps([
  connectCloudflareToIdentityProvider,
  addAnSsoApplicationInCloudflare,
  configureAttributeStatements,
  formatTheX509Certificate,
  uploadIdentityProviderMetadata,
]);

export const CloudflareSamlSteps: FC<
  Readonly<CloudflareSamlStepsProps>
> = () => {
  const stepRoutes = useSsoStepRoutes(steps);

  return <SsoStepNavigation steps={steps}>{stepRoutes}</SsoStepNavigation>;
};
