import get from 'lodash.get';
import { PropsWithChildren, ReactElement, useState } from 'react';
import {
  AnyObject,
  RecordStateDispatcher,
} from '../../../hooks/use-record-state';
import { FormDataErrors } from '../../../shared/form-data-provider/form-data-provider';
import {
  Step,
  StepNavigation,
  StepNavigationProps,
  StepWithPath,
} from '../../../shared/step-navigation';
import { useDsyncStore } from '../dsync-store-provider';
import { SwitchDirectoryModal } from '../switch-directory-modal';

interface DsyncStepNavigationProps<TFormData> {
  steps: Step<TFormData>[];
}

interface DsyncStepNavigationEnhancerProps<TFormData extends AnyObject> {
  children: (props: StepNavigationProps<TFormData>) => ReactElement;
  onClickSwitchProvider: () => void;
  steps: Step<TFormData>[];
}

export const DsyncStepNavigationEnhancer = <TFormData extends AnyObject>({
  children,
  steps,
  onClickSwitchProvider: handleSwitchProvider,
}: DsyncStepNavigationEnhancerProps<TFormData>) => {
  const { directory, updateDirectory } = useDsyncStore();

  const stepsWithPath: StepWithPath<TFormData>[] = steps.map((step, index) => ({
    ...step,
    path: `/dsync/configure/${index + 1}`,
  }));

  const handleUpdateWithChanges = async (
    formData: TFormData,
    setFormErrors: RecordStateDispatcher<FormDataErrors<TFormData>>,
  ) => {
    const response = await updateDirectory({ id: directory.id, ...formData });

    // This is carried over from the old Admin Portal. Our Directory Sync error
    // handling does not actually work. This is more of a signpost for later when
    // we do fix the error handling.
    const errors = get(response, [
      'errors',
      '0',
      'extensions',
      'exception',
      'response',
      'errors',
    ]);

    if (errors) {
      setFormErrors(errors);
    }

    if (response?.data?.portal_updateDirectory) {
      setFormErrors({});
    }
  };

  return children({
    onClickSwitchProvider: handleSwitchProvider,
    onUpdateWithChanges: handleUpdateWithChanges,
    provider: directory.type,
    steps: stepsWithPath,
    stepsUrlBase: '/dsync/configure',
    switchProviderText: 'Switch Directory Provider',
  });
};

export const DsyncStepNavigation = <TFormData extends AnyObject>({
  children,
  steps,
}: PropsWithChildren<DsyncStepNavigationProps<TFormData>>) => {
  const [isSwitchProviderModalOpen, setIsSwitchProviderModalOpen] =
    useState(false);
  return (
    <DsyncStepNavigationEnhancer<TFormData>
      onClickSwitchProvider={() => setIsSwitchProviderModalOpen(true)}
      steps={steps}
    >
      {(stepNavigationProps) => (
        <StepNavigation<TFormData> {...stepNavigationProps}>
          {children}
          <SwitchDirectoryModal
            open={isSwitchProviderModalOpen}
            setOpen={setIsSwitchProviderModalOpen}
          />
        </StepNavigation>
      )}
    </DsyncStepNavigationEnhancer>
  );
};
