import { FC } from 'react';
import { Step } from '../../../../shared/step-navigation';
import { useSsoStepRoutes } from '../../hooks/use-sso-step-routes';
import { MetadataXmlFormFields } from '../../interfaces/metadata-form-fields';
import { SsoStepNavigation } from '../../sso-step-navigation';
import { generateSsoSteps } from '../../utils/generate-sso-steps';
import { setupUnsolicitedSaml } from './1-setup-unsolicited-saml';
import { configureSamlAttributes } from './2-configure-saml-attributes';
import { uploadIdentityProviderMetadata } from './3-upload-identity-provider-metadata';

interface ShibbolethUnsolicitedSamlStepsProps {}

const steps: Step<MetadataXmlFormFields>[] = generateSsoSteps([
  setupUnsolicitedSaml,
  configureSamlAttributes,
  uploadIdentityProviderMetadata,
]);

export const ShibbolethUnsolicitedSamlSteps: FC<
  Readonly<ShibbolethUnsolicitedSamlStepsProps>
> = () => {
  const stepRoutes = useSsoStepRoutes(steps);

  return <SsoStepNavigation steps={steps}>{stepRoutes}</SsoStepNavigation>;
};
