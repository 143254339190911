import { FC } from 'react';
import { Step } from '../../../../shared/step-navigation';
import { useSsoStepRoutes } from '../../hooks/use-sso-step-routes';
import { MetadataUrlFormFields } from '../../interfaces/metadata-form-fields';
import { SsoStepNavigation } from '../../sso-step-navigation';
import { generateSsoSteps } from '../../utils/generate-sso-steps';
import { createEnterpriseApplication } from './1-create-enterprise-application';
import { basicSamlConfiguration } from './2-basic-saml-configuration';
import { userAttributesAndClaims } from './3-user-attributes-and-claims';
import { assignPeopleAndGroups } from './4-assign-people-and-groups';
import { uploadIdpMetadata } from './5-upload-idp-metadata';

interface AzureSamlStepsProps {}

const steps: Step<MetadataUrlFormFields>[] = generateSsoSteps([
  createEnterpriseApplication,
  basicSamlConfiguration,
  userAttributesAndClaims,
  assignPeopleAndGroups,
  uploadIdpMetadata,
]);

export const AzureSamlSteps: FC<Readonly<AzureSamlStepsProps>> = () => {
  const stepRoutes = useSsoStepRoutes(steps);

  return <SsoStepNavigation steps={steps}>{stepRoutes}</SsoStepNavigation>;
};
