import { Text } from '@workos-inc/component-library';
import { FC } from 'react';
import { Img } from '../../../../components/image';
import { StepData } from '../../../../shared/step-navigation/interfaces';
import { StepContent } from '../../../../shared/step-navigation/step-content';

const Component: FC = () => (
  <StepContent confirmLabel="I've completed user assignment.">
    <Text>
      Users assigned to the SAML app will be synced. You can assign users by
      going to the "Permissions" tab, and selecting "Add".
    </Text>

    <Img
      className="my-8"
      height={1026}
      src="/images/1fa514fd-2c99-450d-9bdf-c19e9a83ed4a.png"
      width={1366}
    />

    <Text>
      Search for the individual users and/or groups of users that you would like
      to assign to the app, and check the box next to them. Click "Add" when you
      are finished.
    </Text>

    <Img
      className="my-8"
      height={995}
      src="/images/69dd18fd-ef36-4f45-b78f-b9d1531bdc22.png"
      width={1315}
    />
  </StepContent>
);

export const assignUsersToAppInCyberArk: StepData = {
  title: 'Assign users to app in CyberArk',
  component: Component,
};
