import { Text } from '@workos-inc/component-library';
import { FC } from 'react';
import { Img } from '../../../../components/image';
import { StepData } from '../../../../shared/step-navigation/interfaces';
import { metadataXmlFormKeys } from '../../constants/metadata-form-keys';
import { MetadataXmlFormFields } from '../../interfaces/metadata-form-fields';
import { MetadataXmlStep } from '../../metadata-step';

const Component: FC = () => (
  <MetadataXmlStep
    idpEntityIdPlaceholder="https://accounts.google.com/o/saml2?idpid=C00l3qzl9"
    idpSsoUrlPlaceholder="https://accounts.google.com/o/saml2/idp?idpid=C00l3qzl9"
  >
    <Text>
      Select the "Download Metadata" button to download the metadata file, and
      upload it below. Click "Continue".
    </Text>

    <Img
      className="my-8"
      height={1001}
      src="/images/edef0a99-4ff2-4453-99e3-2787d5dc30b8.png"
      width={1239}
    />
  </MetadataXmlStep>
);

export const uploadIdentityProviderMetadata: StepData<MetadataXmlFormFields> = {
  title: 'Upload Identity Provider Metadata',
  component: Component,
  formKeys: metadataXmlFormKeys,
};
