import { useFeatureToggle } from '@flopflip/react-broadcast';

export type FeatureFlags = {
  adpOidcAdminPortal: boolean;
  breatheHrAdminPortal: boolean;
  cezanneHrAdminPortal: boolean;
  fourthHrAdminPortal: boolean;
  googleOAuthProvider: boolean;
  hibobAdminPortal: boolean;
  hidePoweredByWorkos: boolean;
  oktaAdminPortalEnabledForDrata: boolean;
  oktaOinAdminPortalSso: boolean;
  oneLoginOacAdminPortalSso: boolean;
  peopleHrAdminPortal: boolean;
  ripplingSsoAdminPortal: boolean;
  workdayAdminPortal: boolean;
  ripplingAdminPortal: boolean;
  ripplingScimAdminPortal: boolean;
  workosOktaOinAdminPortalSso: boolean;
};

export const defaultFeatureFlags: FeatureFlags = {
  adpOidcAdminPortal: true,
  breatheHrAdminPortal: false,
  cezanneHrAdminPortal: false,
  fourthHrAdminPortal: false,
  googleOAuthProvider: true,
  hibobAdminPortal: false,
  hidePoweredByWorkos: true,
  oktaAdminPortalEnabledForDrata: true,
  oktaOinAdminPortalSso: false,
  oneLoginOacAdminPortalSso: false,
  peopleHrAdminPortal: false,
  ripplingSsoAdminPortal: false,
  workdayAdminPortal: false,
  ripplingAdminPortal: false,
  ripplingScimAdminPortal: false,
  workosOktaOinAdminPortalSso: false,
};

export const useFeature = (feature: keyof FeatureFlags): boolean =>
  useFeatureToggle(feature);
