import { Text } from '@workos-inc/component-library';
import { FC } from 'react';
import { Img } from '../../../../components/image';
import { CopyInput } from '../../../../components/inputs';
import { StepData } from '../../../../shared/step-navigation/interfaces';
import { StepContent } from '../../../../shared/step-navigation/step-content';
import { useSsoStore } from '../../sso-store-provider';

const Component: FC = () => {
  const { connection } = useSsoStore();
  return (
    <StepContent confirmLabel="I’ve completed my Basic SAML Configuration.">
      <Text>Click the "Configuration" tab from the left sidebar.</Text>

      <CopyInput
        id="saml-sp-metadata-url"
        label="Copy this URL/XML"
        value={connection.saml_sp_metadata_url}
      />

      <Text>Submit the "URL/XML".</Text>

      <Img
        className="my-8"
        height={1598}
        src="/images/12b5df2a-1e8a-4f95-ba69-ff7d3b09e4e5.png"
        width={2692}
      />
    </StepContent>
  );
};

export const basicSamlConfiguration: StepData = {
  title: 'Basic SAML Configuration',
  component: Component,
};
