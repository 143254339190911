import { InputGroup, Text } from '@workos-inc/component-library';
import { FC } from 'react';
import { Card } from '../../../../components/card';
import { Img } from '../../../../components/image';
import { StepData } from '../../../../shared/step-navigation/interfaces';
import { StepContent } from '../../../../shared/step-navigation/step-content';
import { useSsoStore } from '../../sso-store-provider';

const Component: FC = () => {
  const { connection } = useSsoStore();

  return (
    <StepContent confirmLabel="I’ve finished and closed the Relying Party Trust Wizard.">
      <Text>
        Click the “Endpoints” tab and confirm that the “SAML Assertion Consumer
        Endpoints” matches the URL below and click “Next”.
      </Text>

      <Img
        className="my-8"
        height={853}
        src="/images/c0c58966-3656-4079-b9c4-1ed01e2d2412.png"
        width={1186}
      />

      <Card>
        <InputGroup
          readOnly
          id="saml_acs_url"
          label="Confirm this URL matches the SAML Assertion Consumer Endpoint"
          name="saml_acs_url"
          value={connection.saml_acs_url}
        />
      </Card>

      <Text>
        Select “Configure claims issuance policy for this application” and
        “Close”.
      </Text>

      <Img
        className="my-8"
        height={853}
        src="/images/a786ee79-750e-464f-ad4c-bdf685a7aec0.png"
        width={1186}
      />
    </StepContent>
  );
};

export const chooseAccessPolicy: StepData = {
  title: 'Choose Access Policy',
  component: Component,
};
