import { Text } from '@workos-inc/component-library';
import { FC } from 'react';
import { Img } from '../../../../components/image';
import { CopyInput } from '../../../../components/inputs';
import { StepData } from '../../../../shared/step-navigation/interfaces';
import { StepContent } from '../../../../shared/step-navigation/step-content';
import { useSsoStore } from '../../sso-store-provider';

const Component: FC = () => {
  const { connection } = useSsoStore();
  return (
    <StepContent confirmLabel="I've completed my SAML Client Configuration.">
      <Text>
        On the Settings page, scroll down and input the ACS URL below in the
        “Valid Redirect URIs” and "Master SAML Processing URL" boxes.
      </Text>

      <CopyInput
        id="saml-acs-url"
        label="Copy this Client SAML Endpoint"
        value={connection.saml_acs_url}
      />

      <Img
        className="my-8"
        height={1292}
        src="/images/a1dea2f2-ea25-44c9-b88e-39b689a37de2.png"
        width={2066}
      />

      <Text>
        Scroll down further on the Settings page to "Signature and Encryption",
        and make sure that "Sign assertions" is toggled `On`. Click “Save”.
      </Text>

      <Img
        className="my-8"
        height={1484}
        src="/images/dfd9fc38-79b8-4c7d-8ca7-17d1925e8fa2.png"
        width={2528}
      />
    </StepContent>
  );
};

export const samlClientConfiguration: StepData = {
  title: 'SAML Client Configuration',
  component: Component,
};
