import { InputGroup, Text } from '@workos-inc/component-library';
import { FC } from 'react';
import { Card } from '../../../../components/card';
import { Form } from '../../../../components/form';
import { useFormContext } from '../../../../shared/form-data-provider/form-data-provider';
import { StepData } from '../../../../shared/step-navigation/interfaces';
import { useStepsStore } from '../../../../shared/step-navigation/steps-store-provider';
import { BambooHrFormData } from './bamboo-hr-steps';

const Component: FC = () => {
  const { handleInputChange, handleNextStep, isUpdating } = useStepsStore();
  const { formData, formErrors } = useFormContext<BambooHrFormData>();

  return (
    <>
      <Text>
        Upload the API key you generated in your last step, along with your
        company's BambooHR subdomain. If you access BambooHR at
        https://mycompany.bamboohr.com, then the sudomain is "mycompany".
      </Text>

      <Card>
        <Form
          disabled={
            !formData?.bamboo_hr_api_key || !formData?.bamboo_hr_subdomain
          }
          isLoading={isUpdating}
          isUpdate={
            !!(formErrors?.bamboo_hr_api_key || formErrors?.bamboo_hr_subdomain)
          }
          onSubmit={handleNextStep}
        >
          <InputGroup
            autoFocus
            error={formErrors?.bamboo_hr_api_key?.message}
            id="bamboo_hr_api_key"
            label="1. BambooHR API Key"
            name="bamboo_hr_api_key"
            onChange={handleInputChange}
            placeholder=""
            type="password"
            value={formData.bamboo_hr_api_key ?? ''}
          />

          <InputGroup
            error={formErrors?.bamboo_hr_subdomain?.message}
            id="bamboo_hr_subdomain"
            label="2. Your Company's BambooHR Subdomain"
            name="bamboo_hr_subdomain"
            onChange={handleInputChange}
            placeholder="mycompany"
            value={formData.bamboo_hr_subdomain ?? ''}
          />
        </Form>
      </Card>
    </>
  );
};

export const uploadBambooHrApiKeyAndSubdomain: StepData<BambooHrFormData> = {
  title: 'Upload BambooHR API Key and Subdomain',
  component: Component,
  formKeys: ['bamboo_hr_api_key', 'bamboo_hr_subdomain'],
};
