import { FC } from 'react';
import { Step } from '../../../../shared/step-navigation/interfaces';
import { DsyncStepNavigation } from '../../dsync-step-navigation';
import { useDsyncStepRoutes } from '../../hooks/use-dsync-step-routes';
import { useEnabledDsyncSteps } from '../../hooks/use-enabled-dsync-steps';
import { DsyncFormData } from '../../interfaces/dsync-form-data';
import { generateDsyncSteps } from '../../utils/generate-dsync-steps';
import { generateApiKeyInAccessPeopleHr } from './1-generate-api-key-in-access-people-hr';
import { uploadAccessPeopleHrApiKey } from './2-upload-access-people-hr-api-key';
import { setUpAttributeMapping } from './3-set-up-attribute-mapping';

interface PeopleHrStepsProps {}

export type PeopleHrFormData = DsyncFormData<'peopleHrApiKey'>;

const steps: Step<PeopleHrFormData>[] = generateDsyncSteps([
  generateApiKeyInAccessPeopleHr,
  uploadAccessPeopleHrApiKey,
  setUpAttributeMapping,
]);

export const PeopleHrSteps: FC<Readonly<PeopleHrStepsProps>> = () => {
  const enabledSteps = useEnabledDsyncSteps(steps);
  const stepRoutes = useDsyncStepRoutes<PeopleHrFormData>(enabledSteps);

  return (
    <DsyncStepNavigation steps={enabledSteps}>{stepRoutes}</DsyncStepNavigation>
  );
};
