import { Text } from '@workos-inc/component-library';
import { FC } from 'react';
import { Img } from '../../../../components/image';
import { StepData } from '../../../../shared/step-navigation/interfaces';
import { MapAttributesStep } from '../../map-attributes-step/map-attributes-step';

const Component: FC = () => (
  <MapAttributesStep>
    <Text>
      WorkOS will include all of the fields that Directory Providers supply in
      the "raw_attributes" object of the directory user payloads. You can
      standardize how the application receives these "raw_attributes" by
      configuring custom attribute mappings.
    </Text>

    <Text>
      Each Directory Provider may send different attributes, so the most
      accurate way to know which keys are available to map will be to view a
      directory user payload to see which "raw_attributes" are available.
    </Text>

    <Img
      height={1200}
      src="/admin-portal/directory-sync/bamboo-hr/v1/bamboo-hr-5.png"
      width={1450}
    />

    <Text>
      For Instance, if you wanted to map the "homeEmail" raw attribute, you
      would enter "homeEmail" in the "Directory Provider Value" input below.
    </Text>
  </MapAttributesStep>
);

export const setUpAttributeMapping: StepData = {
  title: 'Set up Attribute Mapping',
  customAttributesStep: true,
  component: Component,
};
