import { FC } from 'react';
import { Step } from '../../../../shared/step-navigation/interfaces';
import { DsyncStepNavigation } from '../../dsync-step-navigation';
import { useDsyncStepRoutes } from '../../hooks/use-dsync-step-routes';
import { useEnabledDsyncSteps } from '../../hooks/use-enabled-dsync-steps';
import { generateDsyncSteps } from '../../utils/generate-dsync-steps';
import { selectJumpCloudApplication } from './1-select-jump-cloud-application';
import { configureJumpCloudScim } from './2-configure-jump-cloud-scim';
import { assignUserGroupsToYourApplication } from './3-assign-user-groups-to-your-application';
import { setUpAttributeMapping } from './4-set-up-attribute-mapping';

interface JumpCloudScimStepsProps {}

const steps: Step[] = generateDsyncSteps([
  selectJumpCloudApplication,
  configureJumpCloudScim,
  assignUserGroupsToYourApplication,
  setUpAttributeMapping,
]);

export const JumpCloudScimSteps: FC<Readonly<JumpCloudScimStepsProps>> = () => {
  const enabledSteps = useEnabledDsyncSteps(steps);
  const stepRoutes = useDsyncStepRoutes(enabledSteps);

  return (
    <DsyncStepNavigation steps={enabledSteps}>{stepRoutes}</DsyncStepNavigation>
  );
};
