import { Text } from '@workos-inc/component-library';
import { FC } from 'react';
import { Img } from '../../../../components/image';
import { CopyInput } from '../../../../components/inputs';
import { StepData } from '../../../../shared/step-navigation/interfaces';
import { StepContent } from '../../../../shared/step-navigation/step-content';
import { useSsoStore } from '../../sso-store-provider';

const Component: FC = () => {
  const { connection } = useSsoStore();

  return (
    <StepContent confirmLabel="I’ve uploaded the SSO URL as the ACS URL and SP Entity ID into Rippling.">
      <Text>
        Scrolling down on the SSO Setup Instructions, Rippling will request the
        ACS URL and Service Provider Entity ID.
      </Text>

      <CopyInput
        id="saml-acs-url-copy-button"
        label="Copy this ACS URL"
        value={connection.saml_acs_url}
      />

      <CopyInput
        id="saml-entity-id-copy-button"
        label="Copy this SP Entity ID"
        value={connection.saml_entity_id}
      />

      <Text>Submit the ACS URL and the SP Entity ID.</Text>

      <Img
        className="my-8"
        height={1243}
        src="/images/b1ed0332-a362-4ecd-9fc4-511cb48e4064.png"
        width={1920}
      />

      <Text>Click on the "Move To Next Step" button.</Text>
    </StepContent>
  );
};

export const uploadAcsUrlAndSpEntityId: StepData = {
  title: 'Upload ACS URL and SP Entity ID',
  component: Component,
};
