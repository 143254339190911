import { Text } from '@workos-inc/component-library';
import { FC } from 'react';
import { Img } from '../../../../components/image';
import { usePortalSession } from '../../../../components/portal-session-provider';
import { StepData } from '../../../../shared/step-navigation/interfaces';
import { StepContent } from '../../../../shared/step-navigation/step-content';
import { useSsoStore } from '../../sso-store-provider';

const Component: FC = () => {
  const { connection } = useSsoStore();
  const { appName } = usePortalSession();

  return (
    <StepContent confirmLabel="I’ve finished naming the SAML Application">
      <Text>
        From the JumpCloud Admin Console, select “SSO” on the navigation to the
        left. Then select the green “+” sign to create a new application.
      </Text>

      <Img
        className="my-8"
        height={1394}
        src="/images/1e1363a9-eaf4-4d42-b398-f1f659a1dd01.png"
        width={2214}
      />

      <Text>In the subsequent pop-up window, select “Custom SAML App”.</Text>

      <Img
        className="my-8"
        height={1438}
        src="/images/18cbf074-e1e5-43df-9d97-e88bc671009c.png"
        width={2494}
      />

      <Text>
        Under "Display Label", give it a descriptive name to easily identify it
        later such as {appName}-{connection.name}.
      </Text>

      <Img
        className="my-8"
        height={1570}
        src="/images/34143c1f-f8de-4246-b3bc-ea87a97abc42.png"
        width={2546}
      />
    </StepContent>
  );
};

export const createSamlApplication: StepData = {
  title: 'Create SAML Application',
  component: Component,
};
