import { InputGroup, Text } from '@workos-inc/component-library';
import { FC } from 'react';
import { Card } from '../../../../components/card';
import { Form } from '../../../../components/form';
import { Img } from '../../../../components/image';
import { useFormContext } from '../../../../shared/form-data-provider';
import { StepData } from '../../../../shared/step-navigation/interfaces';
import { useStepsStore } from '../../../../shared/step-navigation/steps-store-provider';
import { SsoFormMetadata } from '../../interfaces/sso-form-metadata';
import { OneLoginSamlOacFormFields } from './onelogin-saml-oac-steps';

const Component: FC = () => {
  const { formData, formErrors } = useFormContext<SsoFormMetadata>();
  const { handleInputChange, handleNextStep, isUpdating } = useStepsStore();

  return (
    <>
      <Text>
        Navigate back to the “SSO” tab in your Application and copy the SAML 2.0
        Endpoint.
      </Text>

      <Img
        className="my-8"
        height={1558}
        src="/images/737422b0-3fe3-460c-a8a8-d283edfeaea2.png"
        width={2250}
      />

      <Card>
        <Form
          disabled={!formData.saml_idp_url}
          isLoading={isUpdating}
          onSubmit={handleNextStep}
        >
          <InputGroup
            autoFocus
            error={
              formErrors?.saml_idp_url?.message
              // validationErrors?.saml_idp_url?.message
            }
            id="saml_idp_url"
            label="SAML 2.0 Endpoint"
            name="saml_idp_url"
            onChange={handleInputChange}
            placeholder="https://foocorp.onelogin.com/trust/saml2/http-post/sso/..."
            value={formData.saml_idp_url ?? ''}
          />
        </Form>
      </Card>
    </>
  );
};

export const provideSaml20Endpoint: StepData<OneLoginSamlOacFormFields> = {
  title: 'Provide SAML 2.0 Endpoint',
  component: Component,
  formKeys: ['saml_idp_url'],
};
