import { FC } from 'react';
import { Step } from '../../../../shared/step-navigation/interfaces';
import { DsyncStepNavigation } from '../../dsync-step-navigation';
import { useDsyncStepRoutes } from '../../hooks/use-dsync-step-routes';
import { useEnabledDsyncSteps } from '../../hooks/use-enabled-dsync-steps';
import { generateDsyncSteps } from '../../utils/generate-dsync-steps';
import { createAzureAdScimApplication } from './1-create-azure-ad-scim-application';
import { configureTheAzureAdScimAdminCredentials } from './2-configure-the-azure-ad-scim-admin-credentials';
import { setUpAndEnableAttributeMapping } from './3-set-up-and-enable-attribute-mapping';
import { setUpApplicationAttributeMapping } from './4-set-up-application-attribute-mapping';
import { assignPeopleGroupsToAzureAdScimApplication } from './5-assign-people-and-groups-to-azure-ad-scim-application';

interface AzureScimStepsProps {}

const steps: Step[] = generateDsyncSteps([
  createAzureAdScimApplication,
  configureTheAzureAdScimAdminCredentials,
  setUpAndEnableAttributeMapping,
  setUpApplicationAttributeMapping,
  assignPeopleGroupsToAzureAdScimApplication,
]);

export const AzureScimSteps: FC<Readonly<AzureScimStepsProps>> = () => {
  const enabledSteps = useEnabledDsyncSteps(steps);
  const stepRoutes = useDsyncStepRoutes(enabledSteps);

  return (
    <DsyncStepNavigation steps={enabledSteps}>{stepRoutes}</DsyncStepNavigation>
  );
};
