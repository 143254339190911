import { Text } from '@workos-inc/component-library';
import { FC } from 'react';
import { StepData } from '../../../../shared/step-navigation/interfaces';
import { StepContent } from '../../../../shared/step-navigation/step-content';
import { ConnectionAttributeMapping } from '../../attribute-mapping';
import { useSsoStore } from '../../sso-store-provider';

const Component: FC = () => {
  const { connection } = useSsoStore();

  return (
    <StepContent confirmLabel="I've finished configuring the SAML claims.">
      <Text>
        You will need to send the following 4 required attributes in the SAML
        Response: id, email, firstName and lastName.
      </Text>

      <Text>Ensure the following attribute mapping is set:</Text>

      <ConnectionAttributeMapping connectionType={connection.type} />
    </StepContent>
  );
};

export const configureYourSamlClaims: StepData = {
  title: 'Configure your SAML Claims',
  component: Component,
};
