import { Text } from '@workos-inc/component-library';
import { FC } from 'react';
import { Img } from '../../../../components/image';
import { StepData } from '../../../../shared/step-navigation/interfaces';
import { StepContent } from '../../../../shared/step-navigation/step-content';

const Component: FC = () => (
  <StepContent confirmLabel="I've collected the RaaS Endpoints.">
    <Text>
      Now that the User and Group Reports are setup and access to them has been
      configured, you will need to get the RaaS endpoints for both reports. The
      page with the endpoints can be found under Actions {'>'} Web Service {'>'}{' '}
      View URLs in each report.
    </Text>

    <Img
      className="my-8"
      height={1302}
      src="/images/5824bb8b-d731-427a-956f-923e979ae5d0.png"
      width={1980}
    />

    <Text>
      Once on the View URLs page, you will save the URL under the JSON section,
      and input these in the next step.
    </Text>

    <Img
      className="my-8"
      height={1712}
      src="/images/8761a4ab-f6ef-474a-8987-779556ed5d27.png"
      width={1592}
    />
  </StepContent>
);

export const getTheRaasEndpoint: StepData = {
  title: 'Get the Raas Endpoint',
  component: Component,
};
