import { Text } from '@workos-inc/component-library';
import { FC } from 'react';
import { Img } from '../../../../components/image';
import { StepData } from '../../../../shared/step-navigation/interfaces';
import { StepContent } from '../../../../shared/step-navigation/step-content';
import { ConnectionAttributeMapping } from '../../attribute-mapping';
import { useSsoStore } from '../../sso-store-provider';

const Component: FC = () => {
  const { connection } = useSsoStore();

  return (
    <StepContent confirmLabel="I've finished configuring the Attribute Statements and clicked “Save”.">
      <Text>
        Under the "Attribute Mapping" section of the SAML app, select "Add
        Attribute".
      </Text>

      <Img
        className="my-8"
        height={1606}
        src="/images/a00c7b90-c656-4b0e-9fc8-f725100a52d4.png"
        width={2730}
      />

      <Text>
        Map the following four attributes as shown below, and the select "Save".
      </Text>

      <ConnectionAttributeMapping connectionType={connection.type} />

      <Img
        className="my-8"
        height={1606}
        src="/images/275323ad-f50e-4aa9-acea-206f4679cc73.png"
        width={2730}
      />
    </StepContent>
  );
};

export const configureAttributeStatements: StepData = {
  title: 'Configure Attribute Statements',
  component: Component,
};
