import { FC } from 'react';
import { Step } from '../../../../shared/step-navigation/interfaces';
import { DsyncStepNavigation } from '../../dsync-step-navigation';
import { useDsyncStepRoutes } from '../../hooks/use-dsync-step-routes';
import { useEnabledDsyncSteps } from '../../hooks/use-enabled-dsync-steps';
import { DsyncFormData } from '../../interfaces/dsync-form-data';
import { generateDsyncSteps } from '../../utils/generate-dsync-steps';
import { generateApiKeyInBambooHr } from './1-generate-api-key-in-bamboo-hr';
import { uploadBambooHrApiKeyAndSubdomain } from './2-upload-bamboo-hr-api-key-and-domain';
import { setUpAttributeMapping } from './3-set-up-attribute-mapping';

interface BambooHrStepsProps {}

export type BambooHrFormData = DsyncFormData<
  'bamboo_hr_api_key' | 'bamboo_hr_subdomain'
>;

const steps: Step<BambooHrFormData>[] = generateDsyncSteps([
  generateApiKeyInBambooHr,
  uploadBambooHrApiKeyAndSubdomain,
  setUpAttributeMapping,
]);

export const BambooHrSteps: FC<Readonly<BambooHrStepsProps>> = () => {
  const enabledSteps = useEnabledDsyncSteps(steps);
  const stepRoutes = useDsyncStepRoutes<BambooHrFormData>(enabledSteps);

  return (
    <DsyncStepNavigation steps={enabledSteps}>{stepRoutes}</DsyncStepNavigation>
  );
};
