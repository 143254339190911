import { Text } from '@workos-inc/component-library';
import { FC } from 'react';
import { Img } from '../../../../components/image';
import { StepData } from '../../../../shared/step-navigation/interfaces';
import { StepContent } from '../../../../shared/step-navigation/step-content';

const Component: FC = () => (
  <StepContent confirmLabel="I've created a Self-Signed Certificate in Salesforce">
    <Text>
      Log in to your Salesforce Account, and navigate to "Setup" by clicking the
      Settings Cog on the top right and selecting "Setup".
    </Text>

    <Text>
      Once in setup mode you can use the search bar to easily navigate around
      between settings pages. The first page to navigate to is the "Certificate
      and Key Management" page.
    </Text>

    <Img
      className="my-8"
      height={901}
      src="/images/c3c88f8e-bb40-4e21-a0aa-7f8741d37e93.png"
      width={1435}
    />

    <Text>
      If a key does not exist that you would like to use, generate a new one by
      clicking "Create Self-Signed Certificate"
    </Text>

    <Img
      className="my-8"
      height={1904}
      src="/images/62346683-435b-4d72-bfc7-1d35474ff0d1.png"
      width={3336}
    />

    <Text>
      Configure the certificate. It's not necessary to have an Exportable
      Private Key, but if you are using a key-certificate store you can choose
      this option.
    </Text>

    <Img
      className="my-8"
      height={1284}
      src="/images/b7c9e7ed-5797-45d6-92f2-102396c33d2e.png"
      width={3334}
    />
  </StepContent>
);

export const createASelfSignedCertificate: StepData = {
  title: 'Create A Self-Signed Certificate',
  component: Component,
};
