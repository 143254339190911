import { Text } from '@workos-inc/component-library';
import { FC } from 'react';
import { Img } from '../../../../components/image';
import { CopyInput } from '../../../../components/inputs';
import { StepData } from '../../../../shared/step-navigation/interfaces';
import { StepContent } from '../../../../shared/step-navigation/step-content';
import { useSsoStore } from '../../sso-store-provider';

const Component: FC = () => {
  const { connection } = useSsoStore();

  return (
    <StepContent confirmLabel="I've configured the Single sign on URL and Audience URI.">
      <Text>
        From the miniOrange / Xecurify dashboard, select "Apps" on the left side
        navigation. Then, select "Add Application".
      </Text>

      <Img
        className="my-8"
        height={1606}
        src="/images/7b04f89a-e0cb-489b-84b8-4fb650011d88.png"
        width={2730}
      />

      <Text>Under "SAML/WS-FED", select "Create App".</Text>

      <Img
        className="my-8"
        height={1606}
        src="/images/12747857-6d59-4826-b7b7-3c4db30104ad.png"
        width={2730}
      />

      <Text>
        Search for "custom" in the search box and select "Custom SAML App".
      </Text>

      <Img
        className="my-8"
        height={1606}
        src="/images/29aa7fa0-f4d8-4004-8782-2d15728824dd.png"
        width={2730}
      />

      <Text>
        Under the "Basic Settings" tab of the SAML app, select "Import SP
        Metadata".
      </Text>

      <Img
        className="my-8"
        height={1606}
        src="/images/1cd7ab06-e8ec-4290-8b34-3e040d175c72.png"
        width={2730}
      />

      <Text>
        Give the SAML app a descriptive name under "App Name". Under "SP
        Metadata", select "URL" and input the SP Metadata URL below. Then, hit
        "Import".
      </Text>

      <CopyInput
        id="saml-sp-metadata-url"
        label="Copy this URL"
        value={connection.saml_sp_metadata_url}
      />

      <Img
        className="my-8"
        height={1606}
        src="/images/0955c954-7107-4dbe-9f0b-3b7cb5e12411.png"
        width={2730}
      />

      <Text>
        Make sure that you have the "Sign Assertion" field toggled on.
      </Text>

      <Img
        className="my-8"
        height={1606}
        src="/images/8787363b-0589-4b6b-8083-abcaa61165dc.png"
        width={2730}
      />

      <Text>Select "Next".</Text>

      <Img
        className="my-8"
        height={1606}
        src="/images/d81dcacd-8a7c-4053-bbe8-d85d6d6b4968.png"
        width={2730}
      />
    </StepContent>
  );
};

export const createSamlIntegration: StepData = {
  title: 'Create SAML Integration',
  component: Component,
};
