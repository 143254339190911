import { FC } from 'react';
import { Step } from '../../../../shared/step-navigation/interfaces';
import { DsyncStepNavigation } from '../../dsync-step-navigation';
import { useDsyncStepRoutes } from '../../hooks/use-dsync-step-routes';
import { useEnabledDsyncSteps } from '../../hooks/use-enabled-dsync-steps';
import { generateDsyncSteps } from '../../utils/generate-dsync-steps';
import { selectOrCreateYourCyberArkApplication } from './1-select-or-create-your-cyberark-application';
import { assignUsersToAppInCyberArk } from './2-assign-users-to-app-in-cyberark';
import { configureTheRoleMappingsInCyberArk } from './3-configure-the-role-mappings-in-cyberark';
import { initiateTheDirectorySyncRunInCyberArk } from './4-initiate-the-directory-sync-run-in-cyberark';
import { setUpAttributeMapping } from './5-set-up-attribute-mapping';

interface CyberArkScimStepsProps {}

const steps: Step[] = generateDsyncSteps([
  selectOrCreateYourCyberArkApplication,
  assignUsersToAppInCyberArk,
  configureTheRoleMappingsInCyberArk,
  initiateTheDirectorySyncRunInCyberArk,
  setUpAttributeMapping,
]);

export const CyberArkScimSteps: FC<Readonly<CyberArkScimStepsProps>> = () => {
  const enabledSteps = useEnabledDsyncSteps(steps);
  const stepRoutes = useDsyncStepRoutes(enabledSteps);

  return (
    <DsyncStepNavigation steps={enabledSteps}>{stepRoutes}</DsyncStepNavigation>
  );
};
