import { Text } from '@workos-inc/component-library';
import { FC } from 'react';
import { CopyInput } from '../../../../components/inputs';
import { StepData } from '../../../../shared/step-navigation/interfaces';
import { StepContent } from '../../../../shared/step-navigation/step-content';
import { useSsoStore } from '../../sso-store-provider';

const Component: FC = () => {
  const { connection } = useSsoStore();

  return (
    <StepContent confirmLabel="I've created a SAML application.">
      <Text>
        Create a generic SAML application in your identity provider. You'll need
        the following Assertion Consumer Service (ACS) URL. The ACS URL is the
        location an Identity Provider redirects its authentication response to.
      </Text>

      <CopyInput
        id="saml-acs-url"
        label="Copy this ACS URL"
        value={connection.saml_acs_url}
      />
    </StepContent>
  );
};

export const createAGenericSamlApplication: StepData = {
  title: 'Create a Generic SAML Application',
  component: Component,
};
