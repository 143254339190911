import { InputGroup, Text } from '@workos-inc/component-library';
import { FC } from 'react';
import { Card } from '../../../../components/card';
import { Form } from '../../../../components/form';
import { useFormContext } from '../../../../shared/form-data-provider/form-data-provider';
import { StepData } from '../../../../shared/step-navigation/interfaces';
import { useStepsStore } from '../../../../shared/step-navigation/steps-store-provider';
import { WorkdayFormData } from './workday-steps';

const Component: FC = () => {
  const { handleInputChange, handleNextStep, isUpdating } = useStepsStore();
  const { formData, formErrors } = useFormContext<WorkdayFormData>();
  return (
    <>
      <Text>
        Upload the RaaS endpoints for the User and Group reports that you
        collected in the last step, as well as the username and password of the
        Integration System User.
      </Text>

      <Card>
        <Form
          disabled={
            !formData.workday_raas_endpoint ||
            !formData.workday_raas_group_endpoint ||
            !formData.workday_raas_username ||
            !formData.workday_raas_password
          }
          isLoading={isUpdating}
          isUpdate={
            !!(
              formErrors.workday_raas_endpoint ||
              formErrors.workday_raas_group_endpoint ||
              formErrors.workday_raas_username ||
              formErrors.workday_raas_password
            )
          }
          onSubmit={handleNextStep}
        >
          <InputGroup
            autoFocus
            error={formErrors.workday_raas_endpoint?.message}
            id="workday_raas_endpoint"
            label="User Report Endpoint"
            name="workday_raas_endpoint"
            onChange={handleInputChange}
            placeholder="https://subdomain.workday.com/path/to/report?format=json"
            value={formData.workday_raas_endpoint ?? ''}
          />

          <InputGroup
            error={formErrors.workday_raas_group_endpoint?.message}
            id="workday_raas_group_endpoint"
            label="Group Report Endpoint"
            name="workday_raas_group_endpoint"
            onChange={handleInputChange}
            placeholder="https://subdomain.workday.com/path/to/report?format=json"
            value={formData.workday_raas_group_endpoint ?? ''}
          />

          <InputGroup
            error={formErrors.workday_raas_username?.message}
            id="workday_raas_username"
            label="Username"
            name="workday_raas_username"
            onChange={handleInputChange}
            placeholder="username"
            value={formData.workday_raas_username ?? ''}
          />

          <InputGroup
            error={formErrors.workday_raas_password?.message}
            id="workday_raas_password"
            label="Password"
            name="workday_raas_password"
            onChange={handleInputChange}
            placeholder=""
            type="password"
            value={formData.workday_raas_password ?? ''}
          />
        </Form>
      </Card>
    </>
  );
};

export const uploadWorkdayEndpointsAndCredentials: StepData<WorkdayFormData> = {
  title: 'Upload Workday endpoints and credentials',
  component: Component,
  formKeys: [
    'workday_raas_endpoint',
    'workday_raas_group_endpoint',
    'workday_raas_username',
    'workday_raas_password',
  ],
};
