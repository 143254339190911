import { Text } from '@workos-inc/component-library';
import { FC } from 'react';
import { Img } from '../../../../components/image';
import { CopyInput } from '../../../../components/inputs';
import { StepData } from '../../../../shared/step-navigation/interfaces';
import { StepContent } from '../../../../shared/step-navigation/step-content';
import { useDsyncStore } from '../../dsync-store-provider';

const Component: FC = () => {
  const { directory } = useDsyncStore();

  return (
    <StepContent confirmLabel="I've configured the Admin Credentials.">
      <Text>
        Select "Provisioning" from the "Manage" section found in the navigation
        menu of the SCIM application.
      </Text>

      <Img
        className="my-8"
        height={1272}
        src="/images/c7cb3d02-a99c-4ded-bddf-ff77db2b8ced.png"
        width={2118}
      />

      <Text>Click the "Get Started" button.</Text>

      <Img
        className="my-8"
        height={1272}
        src="/images/8c673e8c-d2ad-4bc6-a1d7-36de348b9bbf.png"
        width={2704}
      />

      <Text>
        Select the "Automatic" Provisioning Mode from the dropdown menu.
      </Text>

      <Img
        className="my-8"
        height={1374}
        src="/images/b84aedad-4e43-41de-a9ec-b64e7c49443e.png"
        width={2040}
      />
      <Text>Copy and paste the endpoint into the "Tenant URL" field.</Text>

      <CopyInput
        id="directory-endpoint"
        label="Copy this Endpoint"
        value={directory.endpoint}
      />

      <Text>Copy and paste the Bearer Token into the Secret Token field.</Text>

      <CopyInput
        id="bearer-token"
        label="Copy this Bearer Token"
        type="password"
        value={directory.bearerToken}
      />

      <Text>
        Click "Test Connection" to receive confirmation that your connection has
        been set up correctly. Then, select "Save" to persist the credentials.
      </Text>
    </StepContent>
  );
};

export const configureTheAzureAdScimAdminCredentials: StepData = {
  title: 'Configure the Azure AD SCIM Admin Credentials',
  component: Component,
};
