import { FC } from 'react';
import { Step } from '../../../../shared/step-navigation';
import { useSsoStepRoutes } from '../../hooks/use-sso-step-routes';
import { MetadataUrlFormFields } from '../../interfaces/metadata-form-fields';
import { SsoStepNavigation } from '../../sso-step-navigation';
import { generateSsoSteps } from '../../utils/generate-sso-steps';
import { createANewSaasApplication } from './1-create-a-new-saas-application';
import { basicSamlConfiguration } from './2-basic-saml-configuration';
import { advancedSamlConfiguration } from './3-advanced-saml-configuration';
import { configureAttributeMap } from './4-configure-attribute-map';
import { uploadMetadataUrl } from './5-upload-metadata-url';

interface VMwareSamlStepsProps {}

const steps: Step<MetadataUrlFormFields>[] = generateSsoSteps([
  createANewSaasApplication,
  basicSamlConfiguration,
  advancedSamlConfiguration,
  configureAttributeMap,
  uploadMetadataUrl,
]);

export const VMwareSamlSteps: FC<Readonly<VMwareSamlStepsProps>> = () => {
  const stepRoutes = useSsoStepRoutes(steps);

  return <SsoStepNavigation steps={steps}>{stepRoutes}</SsoStepNavigation>;
};
