import { PropsWithChildren, useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { ConnectionType, DirectoryType } from '../../../graphql/generated';
import { OverflowPage } from '../../../layouts/page';
import { Article } from '../../components/typography';
import { AnyObject, RecordStateDispatcher } from '../../hooks/use-record-state';
import { ErrorOrigin } from '../../interfaces/errors';
import {
  FormDataErrors,
  useFormContext,
} from '../form-data-provider/form-data-provider';
import { StepWithPath } from './interfaces';
import { StepCompleteEventBase } from './interfaces/step-complete-event-base';
import { Sidebar } from './sidebar';
import { StepTitle } from './step-title';
import { StepsStoreProvider } from './steps-store-provider';

export interface StepNavigationProps<TFormData extends AnyObject> {
  clientSideFormValidator?: (
    formData: TFormData,
  ) => FormDataErrors<TFormData> | undefined;
  onClickSwitchProvider: () => void;
  onUpdateWithChanges: (
    formData: TFormData,
    setFormErrors: RecordStateDispatcher<FormDataErrors<TFormData>>,
  ) => Promise<void>;
  provider: DirectoryType | ConnectionType;
  steps: StepWithPath<TFormData>[];
  stepsCompleteEventTacker?: (arg: StepCompleteEventBase<TFormData>) => void;
  stepsUrlBase: string;
  switchProviderText?: string;
}

export const StepNavigation = <TFormData extends AnyObject>({
  children,
  onClickSwitchProvider: handleClickSwitchProvider,
  onUpdateWithChanges: handleOnUpdateWithChanges,
  clientSideFormValidator,
  stepsCompleteEventTacker,
  provider,
  steps,
  stepsUrlBase,
  switchProviderText = 'Switch Provider',
}: PropsWithChildren<Readonly<StepNavigationProps<TFormData>>>) => {
  const { pathname } = useLocation();
  const { formErrors } = useFormContext<TFormData>();

  const stepsWithServerSideErrors = steps.map(
    (step) =>
      !!step.formKeys?.some(
        (key) =>
          !!formErrors[key] &&
          formErrors[key]?.errorOrigin === ErrorOrigin.Server,
      ),
  );

  useEffect(() => {
    window.scrollTo({
      top: 0,
      behavior: 'smooth',
    });
  }, [pathname]);

  return (
    <StepsStoreProvider<TFormData>
      clientSideFormValidator={clientSideFormValidator}
      onUpdateWithChanges={handleOnUpdateWithChanges}
      steps={steps}
      stepsCompleteEventTacker={stepsCompleteEventTacker}
      stepsUrlBase={stepsUrlBase}
    >
      <OverflowPage>
        <section className="grid grid-cols-[300px_680px] gap-20">
          <Sidebar
            onClickSwitchProvider={handleClickSwitchProvider}
            provider={provider}
            steps={steps}
            stepsWithErrors={stepsWithServerSideErrors}
            switchProviderText={switchProviderText}
          />

          <Article>
            <StepTitle steps={steps} />

            {children}
          </Article>
        </section>
      </OverflowPage>
    </StepsStoreProvider>
  );
};
