import { Text } from '@workos-inc/component-library';
import { FC } from 'react';
import { Img } from '../../../../components/image';
import { StepData } from '../../../../shared/step-navigation/interfaces';
import { StepContent } from '../../../../shared/step-navigation/step-content';
import { ConnectionAttributeMapping } from '../../attribute-mapping';
import { useSsoStore } from '../../sso-store-provider';

const Component: FC = () => {
  const { connection } = useSsoStore();
  return (
    <StepContent confirmLabel="I've configured the SAML Assertion.">
      <Text>
        On the "Assertion Creation" page, select the "Configure Assertion
        Creation" button.
      </Text>

      <Img
        className="my-8"
        height={982}
        src="/images/0018d0d2-f3db-4c34-8bd5-de217080028c.png"
        width={2366}
      />

      <Text>
        On the Identity Mapping page, select the "Standard" option and click
        "Next".
      </Text>

      <Img
        className="my-8"
        height={966}
        src="/images/6a7d9945-7079-42e6-add7-08b561b1254b.png"
        width={2366}
      />

      <Text>
        On the "Attribute Contract" page, define at least id, email, firstName
        and lastName attributes, as shown below, and then click "Next".
      </Text>

      <ConnectionAttributeMapping connectionType={connection.type} />

      <Img
        className="my-8"
        height={1132}
        src="/images/ddd48c42-adcd-44f6-850d-8d5008f034fd.png"
        width={2366}
      />

      <Text>
        On the "Authentication Source Mapping" page, define the attribute
        mapping for your SAML setup. This can vary based on how you have
        PingFederate configured. Below, we describe an example that uses an
        Authentication Policy and user information from an LDAP server.
      </Text>

      <Img
        className="my-8"
        height={906}
        src="/images/a5d4018e-44bc-450e-a684-c45c2d8bd74a.png"
        width={2366}
      />

      <Text>
        On the "Authentication Policy Mapping" setup, we define the following
        "Attribute Contract Fulfillment" settings to map the attributes to
        content from the policy or the LDAP server.
      </Text>

      <Img
        className="my-8"
        height={1118}
        src="/images/d841c054-3c21-409f-a37a-458721feac88.png"
        width={2458}
      />
    </StepContent>
  );
};

export const configureAssertionCreation: StepData = {
  title: 'Configure Assertion Creation',
  component: Component,
};
