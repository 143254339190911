import { Text } from '@workos-inc/component-library';
import { FC } from 'react';
import { Img } from '../../../../components/image';
import { usePortalSession } from '../../../../components/portal-session-provider';
import { StepData } from '../../../../shared/step-navigation/interfaces';
import { StepContent } from '../../../../shared/step-navigation/step-content';

const Component: FC = () => {
  const { appName } = usePortalSession();
  return (
    <StepContent confirmLabel="I've entered the App Details.">
      <Text>
        Enter an App name and icon (if applicable) for {appName}, then select
        "Continue".
      </Text>

      <Img
        className="my-8"
        height={1009}
        src="/images/3ab3a0af-d6b0-4709-ba0d-46db60d883b9.png"
        width={1474}
      />
    </StepContent>
  );
};

export const enterDetailsForYourCustomApp: StepData = {
  title: 'Enter Details for your Custom App',
  component: Component,
};
