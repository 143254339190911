import { FC } from 'react';
import { Step } from '../../../../shared/step-navigation';
import { useSsoStepRoutes } from '../../hooks/use-sso-step-routes';
import { SsoStepNavigation } from '../../sso-step-navigation';
import { generateSsoSteps } from '../../utils/generate-sso-steps';
import { provideIdpInformation } from './1-provide-idp-information';
import { createAnApplicationWithYourIdp } from './2-create-an-application-with-your-idp';
import { addClaimsToUserIdToken } from './3-add-claims-to-user-id-token';
import { provideYourClientCredentials } from './4-provide-your-client-credentials';
import { provideYourDiscoveryEndpoint } from './5-provide-your-discovery-endpoint';

interface GenericOidcStepsProps {}

export type GenericOidcFormFields = {
  oidc_client_id: string;
  oidc_client_secret: string;
  oidc_discovery_endpoint: string;
};

const steps: Step<GenericOidcFormFields>[] = generateSsoSteps([
  provideIdpInformation,
  createAnApplicationWithYourIdp,
  addClaimsToUserIdToken,
  provideYourClientCredentials,
  provideYourDiscoveryEndpoint,
]);

export const GenericOidcSteps: FC<Readonly<GenericOidcStepsProps>> = () => {
  const stepRoutes = useSsoStepRoutes(steps);

  return <SsoStepNavigation steps={steps}>{stepRoutes}</SsoStepNavigation>;
};
