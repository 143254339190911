import { FC } from 'react';
import { Step } from '../../../../shared/step-navigation/interfaces';
import { DsyncStepNavigation } from '../../dsync-step-navigation';
import { useDsyncStepRoutes } from '../../hooks/use-dsync-step-routes';
import { useEnabledDsyncSteps } from '../../hooks/use-enabled-dsync-steps';
import { generateDsyncSteps } from '../../utils/generate-dsync-steps';
import { provideDirectoryInformation } from './1-provide-directory-information';
import { configureDirectoryProvider } from './2-configure-directory-provider';
import { setUpAttributeMapping } from './3-set-up-attribute-mapping';

interface GenericScimStepsProps {}

const steps: Step[] = generateDsyncSteps([
  provideDirectoryInformation,
  configureDirectoryProvider,
  setUpAttributeMapping,
]);

export const GenericScimSteps: FC<Readonly<GenericScimStepsProps>> = () => {
  const enabledSteps = useEnabledDsyncSteps(steps);
  const stepRoutes = useDsyncStepRoutes(enabledSteps);

  return (
    <DsyncStepNavigation steps={enabledSteps}>{stepRoutes}</DsyncStepNavigation>
  );
};
