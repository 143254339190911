import { Text } from '@workos-inc/component-library';
import { FC } from 'react';
import { Img } from '../../../../components/image';
import { usePortalSession } from '../../../../components/portal-session-provider';
import { StepData } from '../../../../shared/step-navigation/interfaces';
import { StepContent } from '../../../../shared/step-navigation/step-content';

const Component: FC = () => {
  const { appName } = usePortalSession();

  return (
    <StepContent confirmLabel="I’ve created my Azure AD SCIM application.">
      <Text>
        From your Enterprise Application dashboard, select "New Application".
      </Text>

      <Img
        className="my-8"
        height={1440}
        src="/images/8b47e2e9-49f1-40c0-8821-a2911804d39f.png"
        width={2702}
      />

      <Text>Select "Create your own application" and continue.</Text>

      <Img
        className="my-8"
        height={1616}
        src="/images/008b7662-dfee-4088-a956-c4c39b46557b.png"
        width={2680}
      />

      <Text>
        Give your application a descriptive name such as{' '}
        <strong>{appName}</strong>, and select the "Integrate any other
        application you don't find in the gallery (Non-gallery)" option, then
        click "Create".
      </Text>

      <Img
        className="my-8"
        height={1616}
        src="/images/27fbdf37-dd82-4c74-85a9-74be472ff623.png"
        width={2680}
      />
    </StepContent>
  );
};

export const createAzureAdScimApplication: StepData = {
  title: 'Create Azure AD SCIM Application',
  component: Component,
};
